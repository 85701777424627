import { Box, Flex, FormLabel, Stack } from '@chakra-ui/react'
import * as React from 'react'
import IncomeDetailForm from '../../../components/Form/IncomeDetailForm'
import VWRadioGroup from '../../../components/VWRadioGroup'
import { usePreApprovalSteps } from '../../../context/PreApprovalStepsProvider'
import { useData } from '../../../context/UserDataProvider'
import { RadioInput } from '../../../models/RadioInput'

function AffordabilityCalculatorScreen(): React.ReactElement {
  type TrueFalse = 'true' | 'false'
  const { employed, updateEmployemntType } = useData()
  const { isStepLoading } = usePreApprovalSteps()

  const employmentTypeValues: RadioInput<TrueFalse>[] = [
    { value: 'true', message: 'Employed' },
    { value: 'false', message: 'Self-Employed' }
  ]

  const handleUpdteEmployment = (value: TrueFalse) => {
    updateEmployemntType?.(value === 'true' ? true : false)
  }
  return (
    <Flex align="center" flexDirection="column">
      <Stack spacing={4} mx="auto" align="center">
        <Box rounded="lg" color={'brand.200'} padding={0}>
          <FormLabel>Your employment status</FormLabel>
          <VWRadioGroup
            currentValue={String(employed)}
            setValue={(value) => {
              handleUpdteEmployment(value as TrueFalse)
            }}
            values={employmentTypeValues}
            isDisabled={isStepLoading}
          />
          <IncomeDetailForm />
        </Box>
      </Stack>
    </Flex>
  )
}

export default AffordabilityCalculatorScreen
