import { Box, Divider, Flex, Spinner, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { DashboardHeader } from '../../components'
import CarCard from '../../components/CarCard'
import SearchBox from '../../components/SearchBox'
import { useAppContext } from '../../context/AppProvider'
import { usePreApprovalSteps } from '../../context/PreApprovalStepsProvider'
import { useData } from '../../context/UserDataProvider'
import {
  FinanceApplication,
  Vehicle,
  VehiclePayload,
  useFetchUserVehiclesLazyQuery,
  useGetPendingFinaceApplicationLazyQuery
} from '../../generated'
import formatFinanceAmount from '../../utils/math/format/formatFinanceAmount'

function DashboardScreen(): React.ReactElement {
  const { setAboutVehicle } = useAppContext()
  const { setPersistedProgress, persistedProgress } = useData()
  const { setCurrentStep } = usePreApprovalSteps()

  const [fetchedUserVehicles, setFetchedUserVehicles] = useState<VehiclePayload>()
  const [fetchAllVehicles, { loading: isLoading }] = useFetchUserVehiclesLazyQuery({
    onCompleted: (res) => {
      setFetchedUserVehicles(res.fetchUserVehicles as VehiclePayload)
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    const getVehicles = async () => {
      await fetchAllVehicles()
    }

    getVehicles()
  }, [])

  const [savedVehicles, setSavedVehicles] = useState<Vehicle[] | undefined>()
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[] | undefined>()

  const [getPendingFinaceApplication] = useGetPendingFinaceApplicationLazyQuery({
    onCompleted: (res) => {
      setPersistedProgress?.(res.getPendingFinaceApplication as FinanceApplication)
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    setAboutVehicle?.(persistedProgress?.progress?.[1])
  }, [persistedProgress])

  useEffect(() => {
    const fetchedVehicleData = async () => {
      const validVehicles = fetchedUserVehicles?.data?.filter((vehicle) => {
        const timeBetween = Date.now() - Number(vehicle.updatedAt)
        if (timeBetween >= 15778800000) return false // Ch)ecks if vehicle was added under 6 months ago
        return true
      })
      setSavedVehicles(validVehicles) //Saves vehicles to be refered to later
      setFilteredVehicles(validVehicles) //This is what is actually refered in the template
      setCurrentStep?.(persistedProgress?.progress.currentStep || 0)
    }

    fetchedVehicleData()
  }, [fetchedUserVehicles])

  React.useEffect(() => {
    getPendingFinaceApplication()
  }, [])

  const searchVehicle = (text: string | undefined): void => {
    if (!text) return setFilteredVehicles(savedVehicles)
    setFilteredVehicles(
      savedVehicles?.filter((vehicle) => {
        const mm = vehicle.make?.toLowerCase().trim() + vehicle.model?.toLocaleLowerCase().trim()
        return mm.includes(text.toLowerCase().trim())
      })
    )
  }

  return (
    <VStack py="3%" px={{ base: '4%', lg: '20%' }}>
      <DashboardHeader />
      <Divider />
      <Box alignSelf="flex-start" width={[400, 600, '100%']} pt={6}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap="16px"
          p={0}
          flexDirection={['column', 'row']}
        >
          <Text
            data-testid="dashboard-recent-application-title"
            variant="title"
            alignSelf="flex-start"
          >
            Recent Applications
          </Text>
          <SearchBox search={searchVehicle} />
        </Flex>
      </Box>
      <Flex minH="30vh" direction="column">
        {filteredVehicles?.length ? (
          filteredVehicles.map((vehicle) => (
            <CarCard
              financeAmount={'R ' + formatFinanceAmount(vehicle.financedAmount.toString())}
              date={Number(vehicle.updatedAt)}
              approval={vehicle.vafStatus.startsWith('A')}
              make={vehicle.make}
              model={vehicle.model}
              key={vehicle.id}
              referenceNumber={vehicle.referenceNumber as string}
            />
          ))
        ) : (
          <Flex width="100%" height="30vh" alignItems="center">
            <Text>No recent applications</Text>
          </Flex>
        )}
        {isLoading && <Spinner />}
      </Flex>
    </VStack>
  )
}

export default DashboardScreen
