import { z } from 'zod'

///////Create User checks
export const createUserDefaultValues = {
  name: '',
  surname: '',
  idNumber: '',
  phoneNumber: '',
  email: '',
  dob: ''
}

export type CreateUserFormValues = typeof createUserDefaultValues

export const ID_NUMBER_REGEX = new RegExp(
  '^([0-9][0-9])(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))([0-9])([0-9]{3})([0-1])([0-9])([0-9])$'
)

export const EMAIL_VALID_REGEX = new RegExp(
  '^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$'
)

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')
export const SA_PHONE_NUMBER_REGEX = new RegExp('^(0)[6-8][0-9]{8}$')

export const createUserSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  surname: z.string().min(1, 'Surname is required'),
  email: z
    .string()
    .min(5, 'Email address is required')
    .regex(EMAIL_VALID_REGEX, 'Please enter a valid email'),
  phoneNumber: z
    .string()
    .min(10, 'Valid phone number is required')
    .regex(SA_PHONE_NUMBER_REGEX, 'Please enter a valid phone number'), //Limits input to numbers only
  idNumber: z.string().min(8, 'IdNumber or Passport is required'),
  dob: z.string().min(0, 'Please provide date of birth')
})

///////Login User checks
export const loginDefaultValues = {
  idNumber: ''
}

export type LoginFormValues = typeof loginDefaultValues

export const loginSchema = z.object({
  idNumber: z.string().min(8, 'Please insert a valid ID or Passport number')
})

export const validateAllFieldsAreFilled = (formValues: CreateUserFormValues): boolean => {
  /* I can't seem to do this iteratively because of type issues */
  if (
    !formValues.name ||
    !formValues.surname ||
    !formValues.email ||
    !formValues.phoneNumber ||
    !formValues.idNumber
  )
    //Can't seem to field error fields individually. Error doesn't show up as expected
    return false
  return true
}

export const validatePhoneNumber = (fullNumber: string): boolean => {
  /**This regular expression will match a string that starts with a plus sign (+),
   * followed by six to fourteen digits and spaces, and ending with a digit. The
   * spaces between the digits are optional, but the regular expression allows
   * for up to one space between each group of digits. */
  return /^\+(?:[0-9] ?){6,14}[0-9]$/.test(fullNumber)
}
