import {
  Button,
  Flex,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useToast
} from '@chakra-ui/react'
import { Buffer } from 'buffer'
import JSZip from 'jszip'
import * as React from 'react'
import { useAuth } from '../../context/AuthProvider'
import { useData } from '../../context/UserDataProvider'
import { useSendSupportingDocumentsMutation } from '../../generated'
import { FileUpload } from '../FormElements'

type Props = {
  isOpen: boolean
  onClose: () => void
  referenceNumber?: string
}

type InputField =
  | 'nationalId'
  | 'bankStatement'
  | 'proofOfAddress'
  | 'selfEmploymentDoc'
  | 'foreignNationalDoc'

type ErrorMessage = { [key in InputField]?: string }

type FormInput = { [key in InputField]?: File[] }

const MAX_FILE_SIZE = 2000000
const MAX_FILES_UPLOADED = 3

const DocumentsUploadModal: React.FC<Props> = ({ isOpen, onClose, referenceNumber }) => {
  const toast = useToast()
  const { financeData } = useData()
  const vafResponse = JSON.parse(financeData ? financeData : JSON.stringify({}))
  const refNumber = vafResponse?.financeIdentifiers?.referenceNumber ?? referenceNumber

  const [file, setFile] = React.useState<FormInput>()
  const [errorMessage, setErrorMessage] = React.useState<ErrorMessage>()
  const [generalErrorMessage, setGeneralErrorMessage] = React.useState<string>()
  const [uploadError, setUploadError] = React.useState<string>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [sendSupportingDocuments] = useSendSupportingDocumentsMutation()
  const { appName } = useAuth()

  const onSubmit = () => {
    if (!isValid()) {
      return
    }

    try {
      if (file) {
        setLoading(true)
        const zip = new JSZip()
        const files = Object.keys(file) as InputField[]
        files.forEach((savedFile) => {
          if ((file[savedFile] as File[]).length > 1) {
            for (let i = 0; i < (file[savedFile] as File[]).length; i++) {
              zip.file((file[savedFile] as File[])[i].name, (file[savedFile] as File[])[i])
            }
          } else {
            zip.file((file[savedFile] as File[])[0].name, (file[savedFile] as File[])[0])
          }
        })
        zip.generateAsync({ type: 'blob' }).then(async (content) => {
          const arrayBuffer = await content.arrayBuffer()
          const buffer = Buffer.from(arrayBuffer).toString('base64')
          sendSupportingDocuments({
            variables: {
              input: {
                encodedFile: buffer,
                appName: appName ?? 'vwfs',
                refNumber: refNumber
              }
            }
          }).then(() => {
            setLoading(false)
            toast({
              title: 'Document(s) Upoaded Successfully!',
              description:
                'Your document(s) have been uploaded successfully. Our team will reach out to you soon.',
              status: 'success',
              duration: 9000,
              isClosable: true,
              colorScheme: 'primaryDark'
            })
            onClose()
          })
        })
      }
    } catch (error) {
      setLoading(false)
      setUploadError('Error uploading your documents. Kindly contact admin!')
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (event.target.files && event.target.files[0].size >= MAX_FILE_SIZE) {
      setErrorMessage({ ...errorMessage, [name]: 'Max file size is 2MB.' })
    } else if (event.target.files && event.target.files.length > MAX_FILES_UPLOADED) {
      setErrorMessage({
        ...errorMessage,
        [name]: `Please upload a maximum of three files per category. Please do not upload any duplicate files.`
      })
    } else if (duplicatedFilesExists({ [name]: event.target.files })) {
      setErrorMessage({ ...errorMessage, [name]: 'The selected file already exists!' })
    } else {
      clearErrorMessage(name)
      setFile({ ...file, [name]: event.target.files })
    }

    setGeneralErrorMessage(undefined)
  }

  const clearErrorMessage = (name: string) => {
    if (errorMessage !== undefined) {
      setErrorMessage({ ...errorMessage, [name]: undefined })
    }
  }

  const duplicatedFilesExists = (newFiles: FormInput) => {
    const allFiles = { ...file, ...newFiles }
    const files = Object.keys(allFiles) as InputField[]
    const fileNames: string[] = []

    files.forEach((category) => {
      for (let i = 0; i < (allFiles[category] as File[]).length; i++) {
        fileNames.push((allFiles[category] as File[])[i].name)
      }
    })

    return new Set(fileNames).size !== fileNames.length
  }

  const isValid = () => {
    let valid: boolean = true
    const errorMessageList = Object.values(errorMessage ?? {}).filter(
      (error) => error !== undefined
    )

    if (errorMessageList.length > 0) {
      valid = false
    }

    if (!file) {
      setGeneralErrorMessage('At least one supporting document is required!')
      valid = false
    }

    return valid
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={['xs', 'sm', 'md', 'lg', 'xl']}>
      <ModalOverlay />
      <ModalContent
        bg="#FFFFFF"
        boxShadow="0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)"
        borderRadius="12px"
      >
        <ModalHeader>Upload Supporting Documents</ModalHeader>
        <UnorderedList>
          <ListItem>Please upload a maximum of 3 files per category.</ListItem>
          <ListItem>Please do not upload duplicate files.</ListItem>
        </UnorderedList>
        <ModalCloseButton />
        <ModalBody>
          <FileUpload
            label="Copy of your national ID"
            name="nationalId"
            onChange={(event) => handleFileChange(event, 'nationalId')}
            errorMessage={errorMessage?.nationalId}
          />
          <FileUpload
            label="Copy of your bank statement"
            name="bankStatement"
            onChange={(event) => handleFileChange(event, 'bankStatement')}
            errorMessage={errorMessage?.bankStatement}
          />
          <FileUpload
            label="Copy of your proof of address"
            name="proofOfAddress"
            onChange={(event) => handleFileChange(event, 'proofOfAddress')}
            errorMessage={errorMessage?.proofOfAddress}
          />
          <FileUpload
            label="Additional documents for foreign nationals (optional)"
            name="foreignNationalDoc"
            onChange={(event) => handleFileChange(event, 'foreignNationalDoc')}
            errorMessage={errorMessage?.foreignNationalDoc}
          />
          <FileUpload
            label="Additional documents for self employment (optional)"
            name="selfEmploymentDoc"
            onChange={(event) => handleFileChange(event, 'selfEmploymentDoc')}
            errorMessage={errorMessage?.selfEmploymentDoc}
          />
          {!!generalErrorMessage && (
            <Text color="red" textAlign="center" mt={4}>
              {generalErrorMessage}
            </Text>
          )}
          <Flex mt="10px" justifyContent="center" py="20px">
            <Text color="red">{uploadError}</Text>
            <Button
              data-testid="upload-documents-upload-button"
              w="103px"
              padding="20px"
              onClick={onSubmit}
              isLoading={loading}
            >
              Upload
            </Button>
          </Flex>
          <Flex direction="column" justify="flex-start">
            <Text variant="label">Don&apos;t have your documents available at the moment?</Text>
            <Text variant="faint">
              Submit them via email to{' '}
              <Link color="brand.500" href={`mailto:{email}`} isExternal>
                online@vwfs.co.za
              </Link>{' '}
              at your soonest convenience.
            </Text>
            <Text variant="faint" fontWeight={500}>
              We require a copy of:
            </Text>
            <UnorderedList>
              <ListItem>National Identity Document</ListItem>
              <ListItem>Latest Payslip</ListItem>
              <ListItem>3 Months Bank Statement</ListItem>
            </UnorderedList>
            <Text variant="label">Wish to follow up on this QuickApp application?</Text>
            <Text variant="faint">
              Send an email to{' '}
              <Link color="brand.500" href={`mailto:{email}`} isExternal>
                online@vwfs.co.za
              </Link>{' '}
              with your Identity or Reference number and we will assist you further.
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DocumentsUploadModal
