import { AddIcon } from '@chakra-ui/icons'
import { Button, Flex, Spinner, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import { useData } from '../../context/UserDataProvider'
import { Calculator, useGetUserMonthlyIncomeLazyQuery } from '../../generated'
import AffordabilityAmountText from '../AffordabilityAmountText'

export default function DashboardHeader(): React.ReactElement {
  const { user, baseURL } = useAuth()
  const navigate = useNavigate()
  const { persistedProgress } = useData()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [incomeData, setIncomeData] = React.useState<Calculator>()
  const [getIncomeData] = useGetUserMonthlyIncomeLazyQuery({
    onCompleted: (res) => {
      setIncomeData(res.getUserMonthlyIncome as Calculator)
    },
    fetchPolicy: 'no-cache'
  })

  React.useEffect(() => {
    /**
     * It fetches the user's income data from the database and sets the form values to the fetched data
     * @returns the value of the variable income.
     */
    const getUserIncomeData = async () => {
      if (!user) return
      setIsLoading(true)
      await getIncomeData()

      setIsLoading(false)
    }

    getUserIncomeData()
  }, [])

  return (
    <Flex
      flexDirection={['column', 'row']}
      gap="16px"
      justifyContent="space-between"
      alignSelf="stretch"
      alignItems="center"
    >
      <Flex flexDirection="column" alignItems="flex-start" gap="8px">
        <Text data-testid="dashboard-user-name" variant="title">{`Hi ${user?.name ?? ''}`}</Text>
        <Text data-testid="dashboard-affordability-text-one" variant="faint" size="m">
          Depending on the vehicle you choose to finance, you could afford up to:
        </Text>
        {isLoading ? (
          <Spinner />
        ) : (
          <AffordabilityAmountText
            amount={
              incomeData?.affordabilityAmount ? `${incomeData?.affordabilityAmount}` : undefined
            }
          />
        )}
        <Text data-testid="dashboard-affordability-text-two" variant="faint" size="m">
          Provided that you have settled your indicated credit accounts
        </Text>
      </Flex>
      <Button
        data-testid="dashboard-application-button"
        onClick={() =>
          navigate(
            `${baseURL}auth/application?step=${persistedProgress?.progress?.currentStep || 0}`
          )
        }
      >
        <AddIcon />
        {persistedProgress === null ? ' Start Application' : ' Continue with application'}
      </Button>
    </Flex>
  )
}
