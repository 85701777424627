import React, { createContext, ReactNode, useContext, useState } from 'react'
import { AboutVehicle } from '../../models/AboutVehicle'

type AppContextType = {
  aboutVehicle: AboutVehicle
  setAboutVehicle: (vehicle: AboutVehicle) => void
}

export const AppContext = createContext<AppContextType>({
  aboutVehicle: {},
  setAboutVehicle: () => {
    return
  }
})

export const useAppContext = (): AppContextType => useContext(AppContext)

function AppProvider({ children }: { children: ReactNode }): React.ReactElement {
  const [aboutVehicle, setAboutVehicle] = useState<AboutVehicle>({})

  return (
    <AppContext.Provider value={{ aboutVehicle, setAboutVehicle }}>{children}</AppContext.Provider>
  )
}

export default AppProvider
