import { Checkbox, CheckboxProps, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'

type Props = CheckboxProps & {
  supplierName: string
  installmentAmount: string
}

const CreditAccountCard: React.FC<Props> = ({ supplierName, installmentAmount, ...rest }) => {
  return (
    <Flex my={2} width="100%">
      <Flex
        width="100%"
        border="1px"
        borderColor={rest.isChecked ? 'primaryDark.300' : 'base.300'}
        borderRadius="8px"
        padding="16px"
        flexDirection="column"
      >
        <Flex justifyContent="space-between">
          <Text fontWeight={400} fontSize={16} color={rest.isChecked ? 'primary.800' : 'base.900'}>
            {supplierName}
          </Text>
          <Checkbox
            data-testid="credit-card-checkbox"
            {...rest}
            sx={{
              '.chakra-checkbox': {
                '&__control': {
                  width: '20px',
                  height: '20px',
                  borderRadius: '10px',
                  border: '1px solid',
                  borderColor: 'base.300'
                }
              }
            }}
            colorScheme="primary"
          />
        </Flex>
        <Text
          color={rest.isChecked ? 'primaryDark.700' : 'primaryDark.500'}
          fontWeight={400}
          fontSize={20}
        >
          Approx. R{installmentAmount} p/m
        </Text>
      </Flex>
    </Flex>
  )
}

export default CreditAccountCard
