import { Checkbox, CheckboxProps, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'

const DebtSettlementCheckbox: React.FC<CheckboxProps> = ({ ...rest }) => {
  return (
    <Flex width="100%">
      <Flex width="100%" border="1px" borderColor="base.300" borderRadius="8px" padding="16px">
        <Flex flexDirection="column" justifyContent="flex-start" alignItems="start" mr={4}>
          <Checkbox
            data-testid="settlement-checkbox"
            {...rest}
            sx={{
              '.chakra-checkbox': {
                '&__control': {
                  width: '20px',
                  height: '20px',
                  borderRadius: '10px',
                  border: '1px solid',
                  borderColor: 'base.300'
                }
              }
            }}
            colorScheme="primary"
          />
        </Flex>
        <Flex flexDirection="column">
          <Text fontWeight={400} fontSize={16} color="base.800" mb={2}>
            I Accept
          </Text>
          <Text fontWeight={300} fontSize={12} color="base.600">
            I understand if I fail to settle the account(s) selected above, any finance application
            for this vehicle may be declined.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default DebtSettlementCheckbox
