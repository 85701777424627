import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps
} from '@chakra-ui/react'
import * as React from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { FiFile } from 'react-icons/fi'

type FileUploadProps = InputProps & {
  label?: string
  name: FieldPath<FieldValues>
  onFormHelperClick?: () => void
  enableWheelEvent?: boolean
  errorMessage?: string
}

function FileUpload({
  name,
  onChange,
  label,
  errorMessage,
  ...rest
}: FileUploadProps): React.ReactElement {
  return (
    <FormControl isInvalid={!!errorMessage} justifyContent="center" alignItems="center">
      <FormLabel id={name}>{label}</FormLabel>
      <InputGroup>
        <InputLeftElement
          width={'36px'}
          borderRadius={'8px 0px 0px 8px'}
          borderRight={'1px solid #D0D5DD'}
        >
          <Icon as={FiFile} />
        </InputLeftElement>
        <Input
          {...rest}
          onChange={onChange}
          accept=".png,.pdf,.jpg,.jpeg"
          fontWeight={300}
          aria-labelledby={name}
          type="file"
          max={3}
          multiple
        />
      </InputGroup>
      {!!errorMessage && (
        <FormErrorMessage data-testid={`text-input-${name}`}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default FileUpload

FileUpload.defaultProps = {}
