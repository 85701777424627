import { Avatar, Badge, Divider, Flex, FlexProps, Spacer, Text } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import images from '../../theme/images'
type DataInfoCardProps = FlexProps & {
  vehilcle: string
  approval: boolean
  children: ReactElement
}

export default function DataInfoCard({
  vehilcle,
  approval,
  width,
  py,
  children
}: DataInfoCardProps): React.ReactElement {
  return (
    <Flex width={width} py={py}>
      <Flex
        flexDirection="column"
        background="#FFFFFF"
        border="1px solid #E4E7EC"
        borderRadius="8px"
        width="100%"
        alignSelf="flex-start"
      >
        <Flex padding="16px 20px 16px 16px" height="64px" alignItems="center" gap="4px">
          <Avatar src={images.carIcon} />
          <Text variant="title" size="m">
            {vehilcle}
          </Text>
          <Spacer />
          <Badge
            alignSelf="end"
            padding=" 2px 10px"
            borderRadius="16px"
            display="flex"
            height="24px"
            alignItems="center"
            justifyContent="center"
            color="white"
            background={approval ? 'success.500' : 'error.500'}
          >
            {approval ? 'Approved' : 'Not Approved'}
          </Badge>
        </Flex>
        <Divider />
        {children}
      </Flex>
    </Flex>
  )
}
