import { Button, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { PreApprovalStepper, StepsHeader } from '../../components'
import HorizontalStepper from '../../components/HorizontalStepper'
import { usePreApprovalSteps } from '../../context/PreApprovalStepsProvider'
import { useData } from '../../context/UserDataProvider'

export default function ApplicationSection(): React.ReactElement {
  const { currentContent, setCurrentStep, currentStep, contents, currentStepInfo } =
    usePreApprovalSteps()
  const { persistedProgress } = useData()
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const [searchParams] = useSearchParams()
  const routeStep = searchParams.get('step') ?? undefined

  React.useEffect(() => {
    const savedProgress = persistedProgress?.progress

    if (savedProgress) {
      const { currentStep } = savedProgress

      if (routeStep) {
        setCurrentStep?.(Number(routeStep))
      } else if (currentStep) {
        setCurrentStep?.(currentStep)
      }
    }
  }, [])

  React.useEffect(() => {
    setCurrentStep?.(Number(routeStep))
  }, [routeStep])

  return (
    <Flex flexDir="column" px={{ base: '4%', lg: '20%' }}>
      <StepsHeader />
      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        gap="50px"
        padding="20px 0"
        flexGrow={0}
        order={0}
        flex="none"
        width="100%"
      >
        <Flex width="50%" alignItems="flex-start">
          {isMobile ? (
            <HorizontalStepper
              steps={[...Array.from({ length: contents?.length ?? 0 }, (_, i) => i.toString())]}
              currentStep={currentStep?.() as number}
            />
          ) : (
            <PreApprovalStepper />
          )}
        </Flex>
        <Flex flexDirection="column" width="50%">
          <Flex alignItems="flex-start">
            <Text variant="label">{currentStepInfo?.()?.label}</Text>
          </Flex>
          {currentContent?.()}
        </Flex>
      </Flex>
    </Flex>
  )
}

type StepsNavigationProps = {
  primaryAction: () => Promise<boolean>
  secondaryAction: () => void
  isLoading?: boolean
  primaryActionId?: string
}

export function StepsNavigation({
  primaryAction,
  secondaryAction,
  isLoading,
  primaryActionId
}: StepsNavigationProps): React.ReactElement {
  const { currentStep, contents, next, previous } = usePreApprovalSteps()

  return (
    <Flex mt="10px" justifyContent="space-between" pb="20px">
      <Button
        data-testid="application-previous-button"
        w="103px"
        padding="20px"
        isDisabled={currentStep?.() === 0}
        mr={4}
        onClick={() => {
          secondaryAction()
          previous?.()
        }}
        variant="outline"
      >
        Previous
      </Button>
      <Button
        data-testid="application-next-button"
        w="103px"
        padding="20px"
        id={primaryActionId}
        isLoading={isLoading}
        onClick={async () => {
          if (await primaryAction()) {
            next?.()
          }
        }}
      >
        {currentStep?.() === (contents?.length ?? 1) - 1 ? 'Submit' : 'Next'}
      </Button>
    </Flex>
  )
}
