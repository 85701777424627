import { CheckboxGroup, CheckboxGroupProps, Flex } from '@chakra-ui/react'
import * as React from 'react'
import { CreditAccountCard } from '../'
import formatFinanceAmount from '../../utils/math/format/formatFinanceAmount'

export type CreditAccount = {
  accountNumber: string
  installmentAmount: number
  supplierName: string
}

type Props = CheckboxGroupProps & {
  creditAccounts: CreditAccount[]
}

const CreditAccountCardGroup: React.FC<Props> = ({ creditAccounts, ...rest }) => {
  return (
    <CheckboxGroup {...rest}>
      <Flex flexDirection="column" width="100%">
        {creditAccounts.map((creditAccount) => {
          if (creditAccount.accountNumber !== '') {
            return (
              <CreditAccountCard
                data-testid={`checkbox-${creditAccount.supplierName}`}
                isChecked={rest.value?.includes(creditAccount.accountNumber)}
                value={creditAccount.accountNumber}
                key={creditAccount.accountNumber}
                supplierName={creditAccount.supplierName}
                installmentAmount={formatFinanceAmount(`${creditAccount.installmentAmount}`)}
              />
            )
          }
        })}
      </Flex>
    </CheckboxGroup>
  )
}

export default CreditAccountCardGroup
