import { Flex, Text } from '@chakra-ui/react'
import React, { ReactElement } from 'react'

type VehicleInfoCardProps = {
  label: string
  value: string
  labelWidth?: string
}

export default function InfoRowCard({
  label,
  value,
  labelWidth = '130px'
}: VehicleInfoCardProps): ReactElement {
  return (
    <Flex alignItems="center" gap="16px">
      <Text data-testid="card-row-label" variant="faint" w={labelWidth}>
        {label}
      </Text>
      <Text data-testid="card-row-value" variant="label" fontSize="16px" fontWeight="700">
        {value}
      </Text>
    </Flex>
  )
}
