import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import * as React from 'react'
import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form'
import Select, { Props } from 'react-select'
import { useAuth } from '../../../context/AuthProvider'

export const audiBaseStyles = {
  borderRadius: 0,
  border: '1px solid #B3B3B3',
  ':hover': {
    borderColor: '#8B8B8B',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E9E9E9'
  },
  ':focus': {
    borderColor: '#8B8B8B',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E9E9E9'
  }
}

export const vwBaseStyles = {
  borderRadius: '8px',
  border: '1px solid #E4E7EC',
  ':focus': {
    border: '1px solid #99BEFF',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCF1FF'
  },
  ':hover': {
    border: '1px solid #006384',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCF1FF'
  }
}

export const audifsTheme = {
  primary25: '#FF335C',
  primary: '#000000'
}

export const vwfsTheme = {
  primary25: '#00B0F0',
  primary: '#001D50'
}

type SelectInputProps = Props & {
  label?: string
  name: FieldPath<FieldValues>
}

function SelectInput({ name, label, defaultValue, ...rest }: SelectInputProps): React.ReactElement {
  const { control } = useFormContext()
  const { appName } = useAuth()
  const baseStyles = appName === 'audifs' ? audiBaseStyles : vwBaseStyles
  const baseTheme = appName === 'audifs' ? audifsTheme : vwfsTheme
  const { fieldState, field } = useController({
    name,
    control,
    defaultValue
  })

  const showError = !!fieldState.error

  return (
    <FormControl {...fieldState} isInvalid={showError}>
      <FormLabel id={name}>{label}</FormLabel>
      <Select
        data-testid={`select-name-${name}`}
        {...field}
        {...rest}
        defaultValue={defaultValue}
        styles={{
          control: (styles) => ({
            ...styles,
            ...baseStyles,
            height: '40px',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
          })
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            ...baseTheme
          }
        })}
        aria-labelledby={name}
      />
      {showError && (
        <FormErrorMessage data-testid={`error-message-${name}`}>
          {(fieldState.error as any)?.value?.message ?? fieldState.error?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default SelectInput

SelectInput.defaultProps = {}
