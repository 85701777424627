import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  JSON: { input: any; output: any }
}

export type AddFinanceApplicationInput = {
  isCompleted: Scalars['Boolean']['input']
  progress: Scalars['JSON']['input']
}

export type Address = {
  __typename?: 'Address'
  addressLine1: Scalars['String']['output']
  addressLine2: Scalars['String']['output']
  id: Scalars['ID']['output']
  postalCode: Scalars['String']['output']
  residentialStatus: Scalars['String']['output']
  suburb: Scalars['String']['output']
  timeOfLivingMonths: Scalars['Int']['output']
  timeOfLivingYears: Scalars['Int']['output']
  userId: Scalars['String']['output']
}

export type AddressInput = {
  addressLine1: Scalars['String']['input']
  addressLine2?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  residentialStatus: Scalars['String']['input']
  suburb?: InputMaybe<Scalars['String']['input']>
  timeOfLivingMonths: Scalars['Int']['input']
  timeOfLivingYears: Scalars['Int']['input']
}

export type AllProductCategoryFilter = {
  make?: InputMaybe<Scalars['String']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type AllProductFilter = {
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type AuthPayload = {
  __typename?: 'AuthPayload'
  address?: Maybe<Address>
  calculator?: Maybe<Calculator>
  employmentDetails?: Maybe<EmploymentDetails>
  error?: Maybe<Scalars['String']['output']>
  jwt?: Maybe<Scalars['String']['output']>
  maritalStatus?: Maybe<MaritalStatus>
  user?: Maybe<User>
}

export type AuthenticationResponse = {
  __typename?: 'AuthenticationResponse'
  error?: Maybe<Scalars['String']['output']>
  success?: Maybe<Scalars['String']['output']>
}

export type Banks = {
  __typename?: 'Banks'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  points: Scalars['Int']['output']
}

export type BanksInput = {
  name: Scalars['String']['input']
  points: Scalars['Int']['input']
}

export type BanksPayload = {
  __typename?: 'BanksPayload'
  count: Scalars['Int']['output']
  data?: Maybe<Array<Banks>>
}

export type Calculator = {
  __typename?: 'Calculator'
  additionalIncome: Scalars['Float']['output']
  affordabilityAmount: Scalars['Float']['output']
  banksWith: Scalars['String']['output']
  email: Scalars['String']['output']
  employed?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  monthlyExpenses: Scalars['Float']['output']
  monthlyGross: Scalars['Float']['output']
  monthlyNet: Scalars['Float']['output']
  userId: Scalars['String']['output']
}

export type CalculatorInput = {
  additionalIncome: Scalars['Float']['input']
  banksWith: Scalars['String']['input']
  email: Scalars['String']['input']
  employed: Scalars['Boolean']['input']
  eventType: Scalars['String']['input']
  marketingConsent: Scalars['Boolean']['input']
  monthlyExpenses: Scalars['Float']['input']
  monthlyGross: Scalars['Float']['input']
  monthlyNet: Scalars['Float']['input']
  preApprovalRequestBody?: InputMaybe<Scalars['String']['input']>
}

export type CancelFinaceApplicationInput = {
  financeApplicationId: Scalars['String']['input']
  step: Scalars['String']['input']
}

export type DealerExtra = {
  amount: Scalars['Float']['input']
  code: Scalars['String']['input']
}

export type DebtSettlementAccount = {
  __typename?: 'DebtSettlementAccount'
  accountNumber: Scalars['String']['output']
  installmentAmount: Scalars['Float']['output']
  supplierName: Scalars['String']['output']
}

export type DeclinedPreApprovalEmailInput = {
  contactPreference?: InputMaybe<Scalars['String']['input']>
}

export type EmployerTypeFilter = {
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type EmploymentDetails = {
  __typename?: 'EmploymentDetails'
  companyName: Scalars['String']['output']
  employmentTimeInMonths: Scalars['Int']['output']
  employmentTimeInYears: Scalars['Int']['output']
  id: Scalars['ID']['output']
  industry: Scalars['String']['output']
  industryCategory: Scalars['String']['output']
  occupation: Scalars['String']['output']
  phone: Scalars['String']['output']
  userId: Scalars['String']['output']
}

export type EmploymentDetailsInput = {
  asset?: InputMaybe<VehicleAsset>
  companyName: Scalars['String']['input']
  dealerExtra?: InputMaybe<DealerExtra>
  employmentTimeInMonths: Scalars['Int']['input']
  employmentTimeInYears: Scalars['Int']['input']
  industry: Scalars['String']['input']
  industryCategory: Scalars['String']['input']
  isAGraduate: Scalars['Boolean']['input']
  occupation: Scalars['String']['input']
  phone: Scalars['String']['input']
  preApprovalRequestBody?: InputMaybe<Scalars['String']['input']>
}

export type EventFilter = {
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<EventStatus>
  type?: InputMaybe<EventType>
}

export enum EventStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Failed = 'FAILED',
  Successful = 'SUCCESSFUL'
}

export enum EventType {
  CancelFinanceApplication = 'CANCEL_FINANCE_APPLICATION',
  EmploymentDetails = 'EMPLOYMENT_DETAILS',
  EstimateAffordability = 'ESTIMATE_AFFORDABILITY',
  EstimateAffordabilityWithDebtSettlement = 'ESTIMATE_AFFORDABILITY_WITH_DEBT_SETTLEMENT',
  MaritalStatus = 'MARITAL_STATUS',
  PaymentStructure = 'PAYMENT_STRUCTURE',
  SendSupportRequest = 'SEND_SUPPORT_REQUEST',
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
  TellUsAboutTheVehicle = 'TELL_US_ABOUT_THE_VEHICLE',
  UploadSupportingDocuments = 'UPLOAD_SUPPORTING_DOCUMENTS',
  VerifyOtp = 'VERIFY_OTP',
  WhereDoYouLive = 'WHERE_DO_YOU_LIVE'
}

export type FinanceApplication = {
  __typename?: 'FinanceApplication'
  id: Scalars['ID']['output']
  isCompleted?: Maybe<Scalars['Boolean']['output']>
  progress?: Maybe<Scalars['JSON']['output']>
  status?: Maybe<FinanceApplicationStatus>
  userId: Scalars['String']['output']
}

export enum FinanceApplicationStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type GetDebtSettlementInput = {
  debtSettlementRequestBody?: InputMaybe<Scalars['String']['input']>
}

export type IndustryRoleFilter = {
  OccupationType?: InputMaybe<Scalars['String']['input']>
  employerType?: InputMaybe<Scalars['String']['input']>
}

export type IndustryRoleOption = {
  __typename?: 'IndustryRoleOption'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type IndustryRolesPayload = {
  __typename?: 'IndustryRolesPayload'
  employerType: Scalars['String']['output']
  employerTypeCode: Scalars['String']['output']
  id?: Maybe<Scalars['Int']['output']>
  industryType: Scalars['String']['output']
  industryTypeCode: Scalars['String']['output']
  occupationType: Scalars['String']['output']
  occupationTypeCode: Scalars['String']['output']
}

export type LoginInput = {
  idNumber?: InputMaybe<Scalars['String']['input']>
  passportNumber?: InputMaybe<Scalars['String']['input']>
}

export type MmAllSpecs = {
  __typename?: 'MMAllSpecs'
  MMCode: Scalars['String']['output']
  Make: Scalars['String']['output']
  Make_Code?: Maybe<Scalars['String']['output']>
  Master_Model?: Maybe<Scalars['String']['output']>
  Model: Scalars['String']['output']
  Model_Code?: Maybe<Scalars['String']['output']>
  PublicationSection?: Maybe<Scalars['String']['output']>
  RegYear: Scalars['String']['output']
  Variant?: Maybe<Scalars['String']['output']>
  VariantCode?: Maybe<Scalars['String']['output']>
  VehicleType: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type MmAllSpecsInput = {
  query: Scalars['String']['input']
}

export type MmAllSpecsPayload = {
  __typename?: 'MMAllSpecsPayload'
  count: Scalars['Int']['output']
  data: Scalars['String']['output']
}

export type MaritalStatus = {
  __typename?: 'MaritalStatus'
  contractType: Scalars['String']['output']
  id: Scalars['ID']['output']
  status: Scalars['String']['output']
  userId: Scalars['String']['output']
}

export type MaritalStatusInput = {
  contractType: Scalars['String']['input']
  status: Scalars['String']['input']
}

export type Mutation = {
  __typename?: 'Mutation'
  addFinaceApplication?: Maybe<FinanceApplication>
  addUserVehicle?: Maybe<VafPayload>
  cancelFinaceApplication?: Maybe<FinanceApplication>
  deleteOTP?: Maybe<Scalars['Boolean']['output']>
  fetchBanks?: Maybe<BanksPayload>
  fetchMMSpecsData?: Maybe<MmAllSpecsPayload>
  getDebtSettlementAccounts?: Maybe<Array<Maybe<DebtSettlementAccount>>>
  login?: Maybe<AuthenticationResponse>
  register?: Maybe<AuthenticationResponse>
  saveProduct?: Maybe<Scalars['String']['output']>
  saveProductBenefit?: Maybe<Scalars['String']['output']>
  saveProductCategory?: Maybe<Scalars['String']['output']>
  saveProductDisclaimer?: Maybe<Scalars['String']['output']>
  saveUserAdress?: Maybe<Scalars['Boolean']['output']>
  saveUserProducts?: Maybe<Scalars['String']['output']>
  sendDeclinedPreApprovalEmail?: Maybe<Scalars['String']['output']>
  sendSupportRequest?: Maybe<Scalars['String']['output']>
  sendSupportingDocuments?: Maybe<Scalars['String']['output']>
  updateBanks?: Maybe<Scalars['Boolean']['output']>
  updateEmploymentData?: Maybe<VafPayload>
  updateFinaceApplication?: Maybe<FinanceApplication>
  updateMaritalStatus?: Maybe<Scalars['Boolean']['output']>
  updateMonthlyIncome?: Maybe<UpdateMonthlyIncomePayload>
  verifyOtp?: Maybe<AuthPayload>
}

export type MutationAddFinaceApplicationArgs = {
  input: AddFinanceApplicationInput
}

export type MutationAddUserVehicleArgs = {
  input: VehicleInput
}

export type MutationCancelFinaceApplicationArgs = {
  input: CancelFinaceApplicationInput
}

export type MutationDeleteOtpArgs = {
  input: LoginInput
}

export type MutationFetchMmSpecsDataArgs = {
  input: MmAllSpecsInput
}

export type MutationGetDebtSettlementAccountsArgs = {
  input: GetDebtSettlementInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationRegisterArgs = {
  input: RegisterInput
}

export type MutationSaveProductArgs = {
  input: ProductInput
}

export type MutationSaveProductBenefitArgs = {
  input: ProductBenefitInput
}

export type MutationSaveProductCategoryArgs = {
  input: ProductCategoryInput
}

export type MutationSaveProductDisclaimerArgs = {
  input: ProductDisclaimerInput
}

export type MutationSaveUserAdressArgs = {
  input: AddressInput
}

export type MutationSaveUserProductsArgs = {
  input: SaveUserProductsInput
}

export type MutationSendDeclinedPreApprovalEmailArgs = {
  input?: InputMaybe<DeclinedPreApprovalEmailInput>
}

export type MutationSendSupportRequestArgs = {
  input: SupportRequestInput
}

export type MutationSendSupportingDocumentsArgs = {
  input?: InputMaybe<SupportingDocumentsInput>
}

export type MutationUpdateBanksArgs = {
  input: BanksInput
}

export type MutationUpdateEmploymentDataArgs = {
  input: EmploymentDetailsInput
}

export type MutationUpdateFinaceApplicationArgs = {
  input: UpdateFinanceApplicationInput
}

export type MutationUpdateMaritalStatusArgs = {
  input: MaritalStatusInput
}

export type MutationUpdateMonthlyIncomeArgs = {
  input: CalculatorInput
}

export type MutationVerifyOtpArgs = {
  input: VerifyOtpInput
}

export type OccupationTypeFilter = {
  employerType?: InputMaybe<Scalars['String']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type OneProductCategoryFilter = {
  id: Scalars['ID']['input']
}

export type OneVehicleFilter = {
  make: Scalars['String']['input']
  regYear: Scalars['String']['input']
  variant: Scalars['String']['input']
}

export type Product = {
  __typename?: 'Product'
  benefits?: Maybe<Array<Maybe<ProductBenefit>>>
  id: Scalars['ID']['output']
  longName: Scalars['String']['output']
  pricingLogic: Scalars['String']['output']
  pricingValue?: Maybe<Scalars['String']['output']>
  pricingWord?: Maybe<Scalars['String']['output']>
  shortName: Scalars['String']['output']
  term: ProductTerm
  users?: Maybe<Array<Maybe<User>>>
  validityDate: Scalars['String']['output']
}

export type ProductBenefit = {
  __typename?: 'ProductBenefit'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ProductBenefitInput = {
  name: Scalars['String']['input']
  productId: Scalars['String']['input']
}

export type ProductCategoryInput = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type ProductCategoryPayload = {
  __typename?: 'ProductCategoryPayload'
  description: Scalars['String']['output']
  disclaimers?: Maybe<Array<Maybe<ProductDisclaimer>>>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  products?: Maybe<Array<Maybe<Product>>>
}

export type ProductDisclaimer = {
  __typename?: 'ProductDisclaimer'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type ProductDisclaimerInput = {
  description: Scalars['String']['input']
  productCategoryId: Scalars['String']['input']
}

export type ProductInput = {
  longName: Scalars['String']['input']
  make: Scalars['String']['input']
  pricingLogic: Scalars['String']['input']
  pricingValue: Scalars['String']['input']
  pricingWord: Scalars['String']['input']
  productCategoryId: Scalars['String']['input']
  shortName: Scalars['String']['input']
  validityDate: Scalars['String']['input']
}

export type ProductTerm = {
  __typename?: 'ProductTerm'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  value: Scalars['Int']['output']
}

export type Query = {
  __typename?: 'Query'
  allEmployerTypes?: Maybe<Array<Maybe<IndustryRoleOption>>>
  allEvents?: Maybe<Scalars['String']['output']>
  allOccupationTypes?: Maybe<Array<Maybe<IndustryRoleOption>>>
  allSuburbs?: Maybe<Array<Maybe<Suburb>>>
  allVehicleMakes?: Maybe<Array<Maybe<VehicleModelOption>>>
  allVehicleModels?: Maybe<Array<Maybe<VehicleModelOption>>>
  allVehicles?: Maybe<Array<Maybe<VehicleModelPayload>>>
  fetchUserVehicles?: Maybe<VehiclePayload>
  getAllProductCategories?: Maybe<Array<Maybe<ProductCategoryPayload>>>
  getAllProducts?: Maybe<Array<Maybe<Product>>>
  getOneProductCategory?: Maybe<ProductCategoryPayload>
  getPendingFinaceApplication?: Maybe<FinanceApplication>
  getUserMonthlyIncome?: Maybe<Calculator>
  me?: Maybe<User>
  oneIndustryRole?: Maybe<IndustryRolesPayload>
  oneVehicle?: Maybe<VehicleModelPayload>
  purgeInvalidPhones?: Maybe<Scalars['String']['output']>
  updateDateOfBirth?: Maybe<Scalars['String']['output']>
}

export type QueryAllEmployerTypesArgs = {
  filter?: InputMaybe<EmployerTypeFilter>
}

export type QueryAllEventsArgs = {
  filter?: InputMaybe<EventFilter>
}

export type QueryAllOccupationTypesArgs = {
  filter?: InputMaybe<OccupationTypeFilter>
}

export type QueryAllSuburbsArgs = {
  filter?: InputMaybe<SuburbFilter>
}

export type QueryAllVehicleMakesArgs = {
  filter?: InputMaybe<VehicleMakeFilter>
}

export type QueryAllVehicleModelsArgs = {
  filter?: InputMaybe<VehicleModelFilter>
}

export type QueryAllVehiclesArgs = {
  filter?: InputMaybe<VehicleModelFilter>
}

export type QueryGetAllProductCategoriesArgs = {
  filter?: InputMaybe<AllProductCategoryFilter>
}

export type QueryGetAllProductsArgs = {
  filter?: InputMaybe<AllProductFilter>
}

export type QueryGetOneProductCategoryArgs = {
  filter?: InputMaybe<OneProductCategoryFilter>
}

export type QueryOneIndustryRoleArgs = {
  filter?: InputMaybe<IndustryRoleFilter>
}

export type QueryOneVehicleArgs = {
  filter: OneVehicleFilter
}

export type RegisterInput = {
  dob?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  gender?: InputMaybe<Scalars['String']['input']>
  idNumber?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  passportNumber?: InputMaybe<Scalars['String']['input']>
  phoneNumber: Scalars['String']['input']
  surname: Scalars['String']['input']
}

export type SaveUserProductsInput = {
  appName: Scalars['String']['input']
  dealReferenceNumber: Scalars['String']['input']
  products: Array<InputMaybe<SelectedProduct>>
  userId: Scalars['ID']['input']
}

export type SelectedProduct = {
  id: Scalars['ID']['input']
  longName: Scalars['String']['input']
}

export type Suburb = {
  __typename?: 'Suburb'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SuburbFilter = {
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type SupportRequest = {
  __typename?: 'SupportRequest'
  createdAt?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type SupportRequestInput = {
  description: Scalars['String']['input']
  email: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
  phoneNumber: Scalars['String']['input']
}

export type SupportingDocumentsInput = {
  appName: Scalars['String']['input']
  encodedFile: Scalars['String']['input']
  refNumber: Scalars['String']['input']
}

export type UpdateFinanceApplicationInput = {
  id: Scalars['String']['input']
  isCompleted: Scalars['Boolean']['input']
  progress: Scalars['JSON']['input']
  status: FinanceApplicationStatus
}

export type UpdateMonthlyIncomePayload = {
  __typename?: 'UpdateMonthlyIncomePayload'
  preApprovalAttempts: Scalars['Int']['output']
  vafAmount?: Maybe<VafPayload>
}

export type User = {
  __typename?: 'User'
  dob?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  gender?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  idNumber?: Maybe<Scalars['String']['output']>
  marketingConsent: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  passportNumber?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  surname: Scalars['String']['output']
}

export type VafPayload = {
  __typename?: 'VAFPayload'
  amount?: Maybe<Scalars['String']['output']>
  data?: Maybe<Scalars['String']['output']>
  errorMessage?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type Vehicle = {
  __typename?: 'Vehicle'
  feesPaymentMethod?: Maybe<Scalars['String']['output']>
  financedAmount: Scalars['Float']['output']
  id: Scalars['ID']['output']
  make: Scalars['String']['output']
  mmCode?: Maybe<Scalars['String']['output']>
  model: Scalars['String']['output']
  price: Scalars['Float']['output']
  referenceNumber?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['String']['output']
  userId: Scalars['String']['output']
  vafStatus: Scalars['String']['output']
}

export type VehicleAsset = {
  buyingType: Scalars['String']['input']
  dealerCode: Scalars['String']['input']
  mmcode: Scalars['String']['input']
  newUsed: Scalars['String']['input']
  price: Scalars['Float']['input']
  year: Scalars['String']['input']
}

export type VehicleInput = {
  appName: Scalars['String']['input']
  balloonPayment?: InputMaybe<Scalars['Float']['input']>
  buyingType?: InputMaybe<Scalars['String']['input']>
  deposit?: InputMaybe<Scalars['Float']['input']>
  feesPaymentMethod?: InputMaybe<Scalars['String']['input']>
  financeRequestBody?: InputMaybe<Scalars['String']['input']>
  financedAmount: Scalars['Float']['input']
  interestType?: InputMaybe<Scalars['String']['input']>
  make: Scalars['String']['input']
  mmCode?: InputMaybe<Scalars['String']['input']>
  model: Scalars['String']['input']
  monthlyInstallment?: InputMaybe<Scalars['Float']['input']>
  payLoad?: InputMaybe<Scalars['String']['input']>
  paymentTerm?: InputMaybe<Scalars['Int']['input']>
  price: Scalars['Float']['input']
  vafStatus: Scalars['String']['input']
  year?: InputMaybe<Scalars['Int']['input']>
}

export type VehicleMakeFilter = {
  condition?: InputMaybe<Scalars['String']['input']>
  regYear?: InputMaybe<Scalars['String']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type VehicleModelFilter = {
  make?: InputMaybe<Scalars['String']['input']>
  regYear?: InputMaybe<Scalars['String']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type VehicleModelOption = {
  __typename?: 'VehicleModelOption'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type VehicleModelPayload = {
  __typename?: 'VehicleModelPayload'
  id?: Maybe<Scalars['Int']['output']>
  make: Scalars['String']['output']
  makeCode: Scalars['String']['output']
  masterModel: Scalars['String']['output']
  mmCode: Scalars['String']['output']
  model: Scalars['String']['output']
  modelCode: Scalars['String']['output']
  publicationSection: Scalars['String']['output']
  regYear: Scalars['String']['output']
  variant: Scalars['String']['output']
  variantCode: Scalars['String']['output']
  vehicleType: Scalars['String']['output']
}

export type VehiclePayload = {
  __typename?: 'VehiclePayload'
  count: Scalars['Int']['output']
  data?: Maybe<Array<Vehicle>>
}

export type VerifyOtpInput = {
  idNumber?: InputMaybe<Scalars['String']['input']>
  otp: Scalars['String']['input']
  passportNumber?: InputMaybe<Scalars['String']['input']>
}

export type AddUserVehicleMutationVariables = Exact<{
  input: VehicleInput
}>

export type AddUserVehicleMutation = {
  __typename?: 'Mutation'
  addUserVehicle?: {
    __typename?: 'VAFPayload'
    errorMessage?: string | null
    amount?: string | null
    status?: string | null
    data?: string | null
  } | null
}

export type FetchBanksMutationVariables = Exact<{ [key: string]: never }>

export type FetchBanksMutation = {
  __typename?: 'Mutation'
  fetchBanks?: {
    __typename?: 'BanksPayload'
    count: number
    data?: Array<{ __typename?: 'Banks'; id: string; name: string; points: number }> | null
  } | null
}

export type FetchMmSpecsDataMutationVariables = Exact<{
  input: MmAllSpecsInput
}>

export type FetchMmSpecsDataMutation = {
  __typename?: 'Mutation'
  fetchMMSpecsData?: { __typename?: 'MMAllSpecsPayload'; count: number; data: string } | null
}

export type FetchUserVehiclesQueryVariables = Exact<{ [key: string]: never }>

export type FetchUserVehiclesQuery = {
  __typename?: 'Query'
  fetchUserVehicles?: {
    __typename?: 'VehiclePayload'
    count: number
    data?: Array<{
      __typename?: 'Vehicle'
      id: string
      userId: string
      make: string
      model: string
      price: number
      vafStatus: string
      financedAmount: number
      updatedAt: string
      mmCode?: string | null
      referenceNumber?: string | null
    }> | null
  } | null
}

export type AddFinaceApplicationMutationVariables = Exact<{
  input: AddFinanceApplicationInput
}>

export type AddFinaceApplicationMutation = {
  __typename?: 'Mutation'
  addFinaceApplication?: {
    __typename?: 'FinanceApplication'
    id: string
    isCompleted?: boolean | null
    status?: FinanceApplicationStatus | null
    progress?: any | null
  } | null
}

export type UpdateFinaceApplicationMutationVariables = Exact<{
  input: UpdateFinanceApplicationInput
}>

export type UpdateFinaceApplicationMutation = {
  __typename?: 'Mutation'
  updateFinaceApplication?: {
    __typename?: 'FinanceApplication'
    id: string
    isCompleted?: boolean | null
    status?: FinanceApplicationStatus | null
    progress?: any | null
  } | null
}

export type GetPendingFinaceApplicationQueryVariables = Exact<{ [key: string]: never }>

export type GetPendingFinaceApplicationQuery = {
  __typename?: 'Query'
  getPendingFinaceApplication?: {
    __typename?: 'FinanceApplication'
    id: string
    isCompleted?: boolean | null
    status?: FinanceApplicationStatus | null
    progress?: any | null
  } | null
}

export type CancelFinaceApplicationMutationVariables = Exact<{
  input: CancelFinaceApplicationInput
}>

export type CancelFinaceApplicationMutation = {
  __typename?: 'Mutation'
  cancelFinaceApplication?: {
    __typename?: 'FinanceApplication'
    id: string
    isCompleted?: boolean | null
    status?: FinanceApplicationStatus | null
    progress?: any | null
  } | null
}

export type GetDebtSettlementAccountsMutationVariables = Exact<{
  input: GetDebtSettlementInput
}>

export type GetDebtSettlementAccountsMutation = {
  __typename?: 'Mutation'
  getDebtSettlementAccounts?: Array<{
    __typename?: 'DebtSettlementAccount'
    installmentAmount: number
    accountNumber: string
    supplierName: string
  } | null> | null
}

export type GetUserMonthlyIncomeQueryVariables = Exact<{ [key: string]: never }>

export type GetUserMonthlyIncomeQuery = {
  __typename?: 'Query'
  getUserMonthlyIncome?: {
    __typename?: 'Calculator'
    userId: string
    monthlyGross: number
    monthlyNet: number
    additionalIncome: number
    monthlyExpenses: number
    banksWith: string
    email: string
    employed?: boolean | null
    affordabilityAmount: number
  } | null
}

export type LoginMutationVariables = Exact<{
  input: LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login?: {
    __typename?: 'AuthenticationResponse'
    error?: string | null
    success?: string | null
  } | null
}

export type DeleteOtpMutationVariables = Exact<{
  input: LoginInput
}>

export type DeleteOtpMutation = { __typename?: 'Mutation'; deleteOTP?: boolean | null }

export type SaveUserProductsMutationVariables = Exact<{
  input: SaveUserProductsInput
}>

export type SaveUserProductsMutation = { __typename?: 'Mutation'; saveUserProducts?: string | null }

export type RegisterMutationVariables = Exact<{
  input: RegisterInput
}>

export type RegisterMutation = {
  __typename?: 'Mutation'
  register?: {
    __typename?: 'AuthenticationResponse'
    error?: string | null
    success?: string | null
  } | null
}

export type SaveUserAdressMutationVariables = Exact<{
  input: AddressInput
}>

export type SaveUserAdressMutation = { __typename?: 'Mutation'; saveUserAdress?: boolean | null }

export type SendDeclinedPreApprovalEmailMutationVariables = Exact<{
  input?: InputMaybe<DeclinedPreApprovalEmailInput>
}>

export type SendDeclinedPreApprovalEmailMutation = {
  __typename?: 'Mutation'
  sendDeclinedPreApprovalEmail?: string | null
}

export type SendSupportingDocumentsMutationVariables = Exact<{
  input?: InputMaybe<SupportingDocumentsInput>
}>

export type SendSupportingDocumentsMutation = {
  __typename?: 'Mutation'
  sendSupportingDocuments?: string | null
}

export type SendSupportRequestMutationVariables = Exact<{
  input: SupportRequestInput
}>

export type SendSupportRequestMutation = {
  __typename?: 'Mutation'
  sendSupportRequest?: string | null
}

export type UpdateBanksMutationVariables = Exact<{
  input: BanksInput
}>

export type UpdateBanksMutation = { __typename?: 'Mutation'; updateBanks?: boolean | null }

export type UpdateEmploymentDataMutationVariables = Exact<{
  input: EmploymentDetailsInput
}>

export type UpdateEmploymentDataMutation = {
  __typename?: 'Mutation'
  updateEmploymentData?: {
    __typename?: 'VAFPayload'
    errorMessage?: string | null
    amount?: string | null
    status?: string | null
    data?: string | null
  } | null
}

export type UpdateMaritalStatusMutationVariables = Exact<{
  input: MaritalStatusInput
}>

export type UpdateMaritalStatusMutation = {
  __typename?: 'Mutation'
  updateMaritalStatus?: boolean | null
}

export type UpdateMonthlyIncomeMutationVariables = Exact<{
  input: CalculatorInput
}>

export type UpdateMonthlyIncomeMutation = {
  __typename?: 'Mutation'
  updateMonthlyIncome?: {
    __typename?: 'UpdateMonthlyIncomePayload'
    preApprovalAttempts: number
    vafAmount?: {
      __typename?: 'VAFPayload'
      errorMessage?: string | null
      amount?: string | null
      status?: string | null
      data?: string | null
    } | null
  } | null
}

export type VerifyOtpMutationVariables = Exact<{
  input: VerifyOtpInput
}>

export type VerifyOtpMutation = {
  __typename?: 'Mutation'
  verifyOtp?: {
    __typename?: 'AuthPayload'
    jwt?: string | null
    error?: string | null
    user?: {
      __typename?: 'User'
      id: string
      name?: string | null
      email: string
      surname: string
      gender?: string | null
      dob?: string | null
      phoneNumber?: string | null
      passportNumber?: string | null
      idNumber?: string | null
      marketingConsent: boolean
    } | null
    address?: {
      __typename?: 'Address'
      addressLine1: string
      addressLine2: string
      suburb: string
      postalCode: string
      residentialStatus: string
      timeOfLivingYears: number
      timeOfLivingMonths: number
      userId: string
    } | null
    maritalStatus?: {
      __typename?: 'MaritalStatus'
      userId: string
      status: string
      contractType: string
    } | null
    employmentDetails?: {
      __typename?: 'EmploymentDetails'
      userId: string
      companyName: string
      industryCategory: string
      industry: string
      occupation: string
      phone: string
      employmentTimeInYears: number
      employmentTimeInMonths: number
    } | null
    calculator?: {
      __typename?: 'Calculator'
      userId: string
      monthlyGross: number
      monthlyNet: number
      additionalIncome: number
      monthlyExpenses: number
      banksWith: string
      email: string
      employed?: boolean | null
      affordabilityAmount: number
    } | null
  } | null
}

export type AllSuburbsQueryVariables = Exact<{
  filter?: InputMaybe<SuburbFilter>
}>

export type AllSuburbsQuery = {
  __typename?: 'Query'
  allSuburbs?: Array<{ __typename?: 'Suburb'; label: string; value: string } | null> | null
}

export type AllVehiclesQueryVariables = Exact<{
  filter?: InputMaybe<VehicleModelFilter>
}>

export type AllVehiclesQuery = {
  __typename?: 'Query'
  allVehicles?: Array<{
    __typename?: 'VehicleModelPayload'
    id?: number | null
    make: string
    mmCode: string
    makeCode: string
    masterModel: string
    model: string
    modelCode: string
    publicationSection: string
    regYear: string
    variant: string
    variantCode: string
    vehicleType: string
  } | null> | null
}

export type AllVehicleMakesQueryVariables = Exact<{
  filter?: InputMaybe<VehicleMakeFilter>
}>

export type AllVehicleMakesQuery = {
  __typename?: 'Query'
  allVehicleMakes?: Array<{
    __typename?: 'VehicleModelOption'
    label: string
    value: string
  } | null> | null
}

export type AllVehicleModelsQueryVariables = Exact<{
  filter?: InputMaybe<VehicleModelFilter>
}>

export type AllVehicleModelsQuery = {
  __typename?: 'Query'
  allVehicleModels?: Array<{
    __typename?: 'VehicleModelOption'
    label: string
    value: string
  } | null> | null
}

export type OneVehicleQueryVariables = Exact<{
  filter: OneVehicleFilter
}>

export type OneVehicleQuery = {
  __typename?: 'Query'
  oneVehicle?: {
    __typename?: 'VehicleModelPayload'
    id?: number | null
    make: string
    mmCode: string
    makeCode: string
    masterModel: string
    model: string
    modelCode: string
    publicationSection: string
    regYear: string
    variant: string
    variantCode: string
    vehicleType: string
  } | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    name?: string | null
    email: string
    idNumber?: string | null
    passportNumber?: string | null
    surname: string
    gender?: string | null
    dob?: string | null
    phoneNumber?: string | null
  } | null
}

export type GetAllProductCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<AllProductCategoryFilter>
}>

export type GetAllProductCategoriesQuery = {
  __typename?: 'Query'
  getAllProductCategories?: Array<{
    __typename?: 'ProductCategoryPayload'
    id: string
    name: string
    description: string
    disclaimers?: Array<{ __typename?: 'ProductDisclaimer'; description: string } | null> | null
    products?: Array<{
      __typename?: 'Product'
      id: string
      shortName: string
      longName: string
      pricingLogic: string
      pricingValue?: string | null
      pricingWord?: string | null
      benefits?: Array<{ __typename?: 'ProductBenefit'; id: string; name: string } | null> | null
    } | null> | null
  } | null> | null
}

export type AllEmployerTypesQueryVariables = Exact<{
  filter?: InputMaybe<EmployerTypeFilter>
}>

export type AllEmployerTypesQuery = {
  __typename?: 'Query'
  allEmployerTypes?: Array<{
    __typename?: 'IndustryRoleOption'
    label: string
    value: string
  } | null> | null
}

export type AllOccupationTypesQueryVariables = Exact<{
  filter?: InputMaybe<OccupationTypeFilter>
}>

export type AllOccupationTypesQuery = {
  __typename?: 'Query'
  allOccupationTypes?: Array<{
    __typename?: 'IndustryRoleOption'
    label: string
    value: string
  } | null> | null
}

export type OneIndustryRoleQueryVariables = Exact<{
  filter?: InputMaybe<IndustryRoleFilter>
}>

export type OneIndustryRoleQuery = {
  __typename?: 'Query'
  oneIndustryRole?: {
    __typename?: 'IndustryRolesPayload'
    id?: number | null
    employerType: string
    employerTypeCode: string
    industryType: string
    industryTypeCode: string
    occupationType: string
    occupationTypeCode: string
  } | null
}

export const AddUserVehicleDocument = gql`
  mutation addUserVehicle($input: VehicleInput!) {
    addUserVehicle(input: $input) {
      errorMessage
      amount
      status
      data
    }
  }
`
export type AddUserVehicleMutationFn = Apollo.MutationFunction<
  AddUserVehicleMutation,
  AddUserVehicleMutationVariables
>

/**
 * __useAddUserVehicleMutation__
 *
 * To run a mutation, you first call `useAddUserVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserVehicleMutation, { data, loading, error }] = useAddUserVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUserVehicleMutation, AddUserVehicleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddUserVehicleMutation, AddUserVehicleMutationVariables>(
    AddUserVehicleDocument,
    options
  )
}
export type AddUserVehicleMutationHookResult = ReturnType<typeof useAddUserVehicleMutation>
export type AddUserVehicleMutationResult = Apollo.MutationResult<AddUserVehicleMutation>
export type AddUserVehicleMutationOptions = Apollo.BaseMutationOptions<
  AddUserVehicleMutation,
  AddUserVehicleMutationVariables
>
export const FetchBanksDocument = gql`
  mutation fetchBanks {
    fetchBanks {
      count
      data {
        id
        name
        points
      }
    }
  }
`
export type FetchBanksMutationFn = Apollo.MutationFunction<
  FetchBanksMutation,
  FetchBanksMutationVariables
>

/**
 * __useFetchBanksMutation__
 *
 * To run a mutation, you first call `useFetchBanksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchBanksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchBanksMutation, { data, loading, error }] = useFetchBanksMutation({
 *   variables: {
 *   },
 * });
 */
export function useFetchBanksMutation(
  baseOptions?: Apollo.MutationHookOptions<FetchBanksMutation, FetchBanksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FetchBanksMutation, FetchBanksMutationVariables>(
    FetchBanksDocument,
    options
  )
}
export type FetchBanksMutationHookResult = ReturnType<typeof useFetchBanksMutation>
export type FetchBanksMutationResult = Apollo.MutationResult<FetchBanksMutation>
export type FetchBanksMutationOptions = Apollo.BaseMutationOptions<
  FetchBanksMutation,
  FetchBanksMutationVariables
>
export const FetchMmSpecsDataDocument = gql`
  mutation fetchMMSpecsData($input: MMAllSpecsInput!) {
    fetchMMSpecsData(input: $input) {
      count
      data
    }
  }
`
export type FetchMmSpecsDataMutationFn = Apollo.MutationFunction<
  FetchMmSpecsDataMutation,
  FetchMmSpecsDataMutationVariables
>

/**
 * __useFetchMmSpecsDataMutation__
 *
 * To run a mutation, you first call `useFetchMmSpecsDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchMmSpecsDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchMmSpecsDataMutation, { data, loading, error }] = useFetchMmSpecsDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchMmSpecsDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FetchMmSpecsDataMutation,
    FetchMmSpecsDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FetchMmSpecsDataMutation, FetchMmSpecsDataMutationVariables>(
    FetchMmSpecsDataDocument,
    options
  )
}
export type FetchMmSpecsDataMutationHookResult = ReturnType<typeof useFetchMmSpecsDataMutation>
export type FetchMmSpecsDataMutationResult = Apollo.MutationResult<FetchMmSpecsDataMutation>
export type FetchMmSpecsDataMutationOptions = Apollo.BaseMutationOptions<
  FetchMmSpecsDataMutation,
  FetchMmSpecsDataMutationVariables
>
export const FetchUserVehiclesDocument = gql`
  query fetchUserVehicles {
    fetchUserVehicles {
      count
      data {
        id
        userId
        make
        model
        price
        vafStatus
        financedAmount
        updatedAt
        mmCode
        referenceNumber
      }
    }
  }
`

/**
 * __useFetchUserVehiclesQuery__
 *
 * To run a query within a React component, call `useFetchUserVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchUserVehiclesQuery, FetchUserVehiclesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchUserVehiclesQuery, FetchUserVehiclesQueryVariables>(
    FetchUserVehiclesDocument,
    options
  )
}
export function useFetchUserVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchUserVehiclesQuery, FetchUserVehiclesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchUserVehiclesQuery, FetchUserVehiclesQueryVariables>(
    FetchUserVehiclesDocument,
    options
  )
}
export function useFetchUserVehiclesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FetchUserVehiclesQuery,
    FetchUserVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FetchUserVehiclesQuery, FetchUserVehiclesQueryVariables>(
    FetchUserVehiclesDocument,
    options
  )
}
export type FetchUserVehiclesQueryHookResult = ReturnType<typeof useFetchUserVehiclesQuery>
export type FetchUserVehiclesLazyQueryHookResult = ReturnType<typeof useFetchUserVehiclesLazyQuery>
export type FetchUserVehiclesSuspenseQueryHookResult = ReturnType<
  typeof useFetchUserVehiclesSuspenseQuery
>
export type FetchUserVehiclesQueryResult = Apollo.QueryResult<
  FetchUserVehiclesQuery,
  FetchUserVehiclesQueryVariables
>
export const AddFinaceApplicationDocument = gql`
  mutation addFinaceApplication($input: AddFinanceApplicationInput!) {
    addFinaceApplication(input: $input) {
      id
      isCompleted
      status
      progress
    }
  }
`
export type AddFinaceApplicationMutationFn = Apollo.MutationFunction<
  AddFinaceApplicationMutation,
  AddFinaceApplicationMutationVariables
>

/**
 * __useAddFinaceApplicationMutation__
 *
 * To run a mutation, you first call `useAddFinaceApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFinaceApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFinaceApplicationMutation, { data, loading, error }] = useAddFinaceApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFinaceApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFinaceApplicationMutation,
    AddFinaceApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddFinaceApplicationMutation, AddFinaceApplicationMutationVariables>(
    AddFinaceApplicationDocument,
    options
  )
}
export type AddFinaceApplicationMutationHookResult = ReturnType<
  typeof useAddFinaceApplicationMutation
>
export type AddFinaceApplicationMutationResult = Apollo.MutationResult<AddFinaceApplicationMutation>
export type AddFinaceApplicationMutationOptions = Apollo.BaseMutationOptions<
  AddFinaceApplicationMutation,
  AddFinaceApplicationMutationVariables
>
export const UpdateFinaceApplicationDocument = gql`
  mutation updateFinaceApplication($input: UpdateFinanceApplicationInput!) {
    updateFinaceApplication(input: $input) {
      id
      isCompleted
      status
      progress
    }
  }
`
export type UpdateFinaceApplicationMutationFn = Apollo.MutationFunction<
  UpdateFinaceApplicationMutation,
  UpdateFinaceApplicationMutationVariables
>

/**
 * __useUpdateFinaceApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateFinaceApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinaceApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinaceApplicationMutation, { data, loading, error }] = useUpdateFinaceApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFinaceApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFinaceApplicationMutation,
    UpdateFinaceApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFinaceApplicationMutation,
    UpdateFinaceApplicationMutationVariables
  >(UpdateFinaceApplicationDocument, options)
}
export type UpdateFinaceApplicationMutationHookResult = ReturnType<
  typeof useUpdateFinaceApplicationMutation
>
export type UpdateFinaceApplicationMutationResult =
  Apollo.MutationResult<UpdateFinaceApplicationMutation>
export type UpdateFinaceApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateFinaceApplicationMutation,
  UpdateFinaceApplicationMutationVariables
>
export const GetPendingFinaceApplicationDocument = gql`
  query getPendingFinaceApplication {
    getPendingFinaceApplication {
      id
      isCompleted
      status
      progress
    }
  }
`

/**
 * __useGetPendingFinaceApplicationQuery__
 *
 * To run a query within a React component, call `useGetPendingFinaceApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingFinaceApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingFinaceApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPendingFinaceApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPendingFinaceApplicationQuery,
    GetPendingFinaceApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPendingFinaceApplicationQuery,
    GetPendingFinaceApplicationQueryVariables
  >(GetPendingFinaceApplicationDocument, options)
}
export function useGetPendingFinaceApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPendingFinaceApplicationQuery,
    GetPendingFinaceApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPendingFinaceApplicationQuery,
    GetPendingFinaceApplicationQueryVariables
  >(GetPendingFinaceApplicationDocument, options)
}
export function useGetPendingFinaceApplicationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPendingFinaceApplicationQuery,
    GetPendingFinaceApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetPendingFinaceApplicationQuery,
    GetPendingFinaceApplicationQueryVariables
  >(GetPendingFinaceApplicationDocument, options)
}
export type GetPendingFinaceApplicationQueryHookResult = ReturnType<
  typeof useGetPendingFinaceApplicationQuery
>
export type GetPendingFinaceApplicationLazyQueryHookResult = ReturnType<
  typeof useGetPendingFinaceApplicationLazyQuery
>
export type GetPendingFinaceApplicationSuspenseQueryHookResult = ReturnType<
  typeof useGetPendingFinaceApplicationSuspenseQuery
>
export type GetPendingFinaceApplicationQueryResult = Apollo.QueryResult<
  GetPendingFinaceApplicationQuery,
  GetPendingFinaceApplicationQueryVariables
>
export const CancelFinaceApplicationDocument = gql`
  mutation cancelFinaceApplication($input: CancelFinaceApplicationInput!) {
    cancelFinaceApplication(input: $input) {
      id
      isCompleted
      status
      progress
    }
  }
`
export type CancelFinaceApplicationMutationFn = Apollo.MutationFunction<
  CancelFinaceApplicationMutation,
  CancelFinaceApplicationMutationVariables
>

/**
 * __useCancelFinaceApplicationMutation__
 *
 * To run a mutation, you first call `useCancelFinaceApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFinaceApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFinaceApplicationMutation, { data, loading, error }] = useCancelFinaceApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelFinaceApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelFinaceApplicationMutation,
    CancelFinaceApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelFinaceApplicationMutation,
    CancelFinaceApplicationMutationVariables
  >(CancelFinaceApplicationDocument, options)
}
export type CancelFinaceApplicationMutationHookResult = ReturnType<
  typeof useCancelFinaceApplicationMutation
>
export type CancelFinaceApplicationMutationResult =
  Apollo.MutationResult<CancelFinaceApplicationMutation>
export type CancelFinaceApplicationMutationOptions = Apollo.BaseMutationOptions<
  CancelFinaceApplicationMutation,
  CancelFinaceApplicationMutationVariables
>
export const GetDebtSettlementAccountsDocument = gql`
  mutation getDebtSettlementAccounts($input: GetDebtSettlementInput!) {
    getDebtSettlementAccounts(input: $input) {
      installmentAmount
      accountNumber
      supplierName
    }
  }
`
export type GetDebtSettlementAccountsMutationFn = Apollo.MutationFunction<
  GetDebtSettlementAccountsMutation,
  GetDebtSettlementAccountsMutationVariables
>

/**
 * __useGetDebtSettlementAccountsMutation__
 *
 * To run a mutation, you first call `useGetDebtSettlementAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDebtSettlementAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDebtSettlementAccountsMutation, { data, loading, error }] = useGetDebtSettlementAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDebtSettlementAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetDebtSettlementAccountsMutation,
    GetDebtSettlementAccountsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GetDebtSettlementAccountsMutation,
    GetDebtSettlementAccountsMutationVariables
  >(GetDebtSettlementAccountsDocument, options)
}
export type GetDebtSettlementAccountsMutationHookResult = ReturnType<
  typeof useGetDebtSettlementAccountsMutation
>
export type GetDebtSettlementAccountsMutationResult =
  Apollo.MutationResult<GetDebtSettlementAccountsMutation>
export type GetDebtSettlementAccountsMutationOptions = Apollo.BaseMutationOptions<
  GetDebtSettlementAccountsMutation,
  GetDebtSettlementAccountsMutationVariables
>
export const GetUserMonthlyIncomeDocument = gql`
  query getUserMonthlyIncome {
    getUserMonthlyIncome {
      userId
      monthlyGross
      monthlyNet
      additionalIncome
      monthlyExpenses
      banksWith
      email
      employed
      affordabilityAmount
    }
  }
`

/**
 * __useGetUserMonthlyIncomeQuery__
 *
 * To run a query within a React component, call `useGetUserMonthlyIncomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMonthlyIncomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMonthlyIncomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserMonthlyIncomeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserMonthlyIncomeQuery,
    GetUserMonthlyIncomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserMonthlyIncomeQuery, GetUserMonthlyIncomeQueryVariables>(
    GetUserMonthlyIncomeDocument,
    options
  )
}
export function useGetUserMonthlyIncomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserMonthlyIncomeQuery,
    GetUserMonthlyIncomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserMonthlyIncomeQuery, GetUserMonthlyIncomeQueryVariables>(
    GetUserMonthlyIncomeDocument,
    options
  )
}
export function useGetUserMonthlyIncomeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserMonthlyIncomeQuery,
    GetUserMonthlyIncomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetUserMonthlyIncomeQuery, GetUserMonthlyIncomeQueryVariables>(
    GetUserMonthlyIncomeDocument,
    options
  )
}
export type GetUserMonthlyIncomeQueryHookResult = ReturnType<typeof useGetUserMonthlyIncomeQuery>
export type GetUserMonthlyIncomeLazyQueryHookResult = ReturnType<
  typeof useGetUserMonthlyIncomeLazyQuery
>
export type GetUserMonthlyIncomeSuspenseQueryHookResult = ReturnType<
  typeof useGetUserMonthlyIncomeSuspenseQuery
>
export type GetUserMonthlyIncomeQueryResult = Apollo.QueryResult<
  GetUserMonthlyIncomeQuery,
  GetUserMonthlyIncomeQueryVariables
>
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      error
      success
    }
  }
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
export const DeleteOtpDocument = gql`
  mutation deleteOTP($input: LoginInput!) {
    deleteOTP(input: $input)
  }
`
export type DeleteOtpMutationFn = Apollo.MutationFunction<
  DeleteOtpMutation,
  DeleteOtpMutationVariables
>

/**
 * __useDeleteOtpMutation__
 *
 * To run a mutation, you first call `useDeleteOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOtpMutation, { data, loading, error }] = useDeleteOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOtpMutation, DeleteOtpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteOtpMutation, DeleteOtpMutationVariables>(
    DeleteOtpDocument,
    options
  )
}
export type DeleteOtpMutationHookResult = ReturnType<typeof useDeleteOtpMutation>
export type DeleteOtpMutationResult = Apollo.MutationResult<DeleteOtpMutation>
export type DeleteOtpMutationOptions = Apollo.BaseMutationOptions<
  DeleteOtpMutation,
  DeleteOtpMutationVariables
>
export const SaveUserProductsDocument = gql`
  mutation saveUserProducts($input: SaveUserProductsInput!) {
    saveUserProducts(input: $input)
  }
`
export type SaveUserProductsMutationFn = Apollo.MutationFunction<
  SaveUserProductsMutation,
  SaveUserProductsMutationVariables
>

/**
 * __useSaveUserProductsMutation__
 *
 * To run a mutation, you first call `useSaveUserProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserProductsMutation, { data, loading, error }] = useSaveUserProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserProductsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserProductsMutation,
    SaveUserProductsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveUserProductsMutation, SaveUserProductsMutationVariables>(
    SaveUserProductsDocument,
    options
  )
}
export type SaveUserProductsMutationHookResult = ReturnType<typeof useSaveUserProductsMutation>
export type SaveUserProductsMutationResult = Apollo.MutationResult<SaveUserProductsMutation>
export type SaveUserProductsMutationOptions = Apollo.BaseMutationOptions<
  SaveUserProductsMutation,
  SaveUserProductsMutationVariables
>
export const RegisterDocument = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      error
      success
    }
  }
`
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options)
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>
export const SaveUserAdressDocument = gql`
  mutation saveUserAdress($input: AddressInput!) {
    saveUserAdress(input: $input)
  }
`
export type SaveUserAdressMutationFn = Apollo.MutationFunction<
  SaveUserAdressMutation,
  SaveUserAdressMutationVariables
>

/**
 * __useSaveUserAdressMutation__
 *
 * To run a mutation, you first call `useSaveUserAdressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserAdressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserAdressMutation, { data, loading, error }] = useSaveUserAdressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserAdressMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveUserAdressMutation, SaveUserAdressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveUserAdressMutation, SaveUserAdressMutationVariables>(
    SaveUserAdressDocument,
    options
  )
}
export type SaveUserAdressMutationHookResult = ReturnType<typeof useSaveUserAdressMutation>
export type SaveUserAdressMutationResult = Apollo.MutationResult<SaveUserAdressMutation>
export type SaveUserAdressMutationOptions = Apollo.BaseMutationOptions<
  SaveUserAdressMutation,
  SaveUserAdressMutationVariables
>
export const SendDeclinedPreApprovalEmailDocument = gql`
  mutation sendDeclinedPreApprovalEmail($input: DeclinedPreApprovalEmailInput) {
    sendDeclinedPreApprovalEmail(input: $input)
  }
`
export type SendDeclinedPreApprovalEmailMutationFn = Apollo.MutationFunction<
  SendDeclinedPreApprovalEmailMutation,
  SendDeclinedPreApprovalEmailMutationVariables
>

/**
 * __useSendDeclinedPreApprovalEmailMutation__
 *
 * To run a mutation, you first call `useSendDeclinedPreApprovalEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDeclinedPreApprovalEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDeclinedPreApprovalEmailMutation, { data, loading, error }] = useSendDeclinedPreApprovalEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendDeclinedPreApprovalEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendDeclinedPreApprovalEmailMutation,
    SendDeclinedPreApprovalEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendDeclinedPreApprovalEmailMutation,
    SendDeclinedPreApprovalEmailMutationVariables
  >(SendDeclinedPreApprovalEmailDocument, options)
}
export type SendDeclinedPreApprovalEmailMutationHookResult = ReturnType<
  typeof useSendDeclinedPreApprovalEmailMutation
>
export type SendDeclinedPreApprovalEmailMutationResult =
  Apollo.MutationResult<SendDeclinedPreApprovalEmailMutation>
export type SendDeclinedPreApprovalEmailMutationOptions = Apollo.BaseMutationOptions<
  SendDeclinedPreApprovalEmailMutation,
  SendDeclinedPreApprovalEmailMutationVariables
>
export const SendSupportingDocumentsDocument = gql`
  mutation sendSupportingDocuments($input: SupportingDocumentsInput) {
    sendSupportingDocuments(input: $input)
  }
`
export type SendSupportingDocumentsMutationFn = Apollo.MutationFunction<
  SendSupportingDocumentsMutation,
  SendSupportingDocumentsMutationVariables
>

/**
 * __useSendSupportingDocumentsMutation__
 *
 * To run a mutation, you first call `useSendSupportingDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSupportingDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSupportingDocumentsMutation, { data, loading, error }] = useSendSupportingDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSupportingDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSupportingDocumentsMutation,
    SendSupportingDocumentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendSupportingDocumentsMutation,
    SendSupportingDocumentsMutationVariables
  >(SendSupportingDocumentsDocument, options)
}
export type SendSupportingDocumentsMutationHookResult = ReturnType<
  typeof useSendSupportingDocumentsMutation
>
export type SendSupportingDocumentsMutationResult =
  Apollo.MutationResult<SendSupportingDocumentsMutation>
export type SendSupportingDocumentsMutationOptions = Apollo.BaseMutationOptions<
  SendSupportingDocumentsMutation,
  SendSupportingDocumentsMutationVariables
>
export const SendSupportRequestDocument = gql`
  mutation sendSupportRequest($input: SupportRequestInput!) {
    sendSupportRequest(input: $input)
  }
`
export type SendSupportRequestMutationFn = Apollo.MutationFunction<
  SendSupportRequestMutation,
  SendSupportRequestMutationVariables
>

/**
 * __useSendSupportRequestMutation__
 *
 * To run a mutation, you first call `useSendSupportRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSupportRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSupportRequestMutation, { data, loading, error }] = useSendSupportRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSupportRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSupportRequestMutation,
    SendSupportRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendSupportRequestMutation, SendSupportRequestMutationVariables>(
    SendSupportRequestDocument,
    options
  )
}
export type SendSupportRequestMutationHookResult = ReturnType<typeof useSendSupportRequestMutation>
export type SendSupportRequestMutationResult = Apollo.MutationResult<SendSupportRequestMutation>
export type SendSupportRequestMutationOptions = Apollo.BaseMutationOptions<
  SendSupportRequestMutation,
  SendSupportRequestMutationVariables
>
export const UpdateBanksDocument = gql`
  mutation updateBanks($input: BanksInput!) {
    updateBanks(input: $input)
  }
`
export type UpdateBanksMutationFn = Apollo.MutationFunction<
  UpdateBanksMutation,
  UpdateBanksMutationVariables
>

/**
 * __useUpdateBanksMutation__
 *
 * To run a mutation, you first call `useUpdateBanksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBanksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBanksMutation, { data, loading, error }] = useUpdateBanksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBanksMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBanksMutation, UpdateBanksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateBanksMutation, UpdateBanksMutationVariables>(
    UpdateBanksDocument,
    options
  )
}
export type UpdateBanksMutationHookResult = ReturnType<typeof useUpdateBanksMutation>
export type UpdateBanksMutationResult = Apollo.MutationResult<UpdateBanksMutation>
export type UpdateBanksMutationOptions = Apollo.BaseMutationOptions<
  UpdateBanksMutation,
  UpdateBanksMutationVariables
>
export const UpdateEmploymentDataDocument = gql`
  mutation updateEmploymentData($input: EmploymentDetailsInput!) {
    updateEmploymentData(input: $input) {
      errorMessage
      amount
      status
      data
    }
  }
`
export type UpdateEmploymentDataMutationFn = Apollo.MutationFunction<
  UpdateEmploymentDataMutation,
  UpdateEmploymentDataMutationVariables
>

/**
 * __useUpdateEmploymentDataMutation__
 *
 * To run a mutation, you first call `useUpdateEmploymentDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmploymentDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmploymentDataMutation, { data, loading, error }] = useUpdateEmploymentDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmploymentDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmploymentDataMutation,
    UpdateEmploymentDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateEmploymentDataMutation, UpdateEmploymentDataMutationVariables>(
    UpdateEmploymentDataDocument,
    options
  )
}
export type UpdateEmploymentDataMutationHookResult = ReturnType<
  typeof useUpdateEmploymentDataMutation
>
export type UpdateEmploymentDataMutationResult = Apollo.MutationResult<UpdateEmploymentDataMutation>
export type UpdateEmploymentDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmploymentDataMutation,
  UpdateEmploymentDataMutationVariables
>
export const UpdateMaritalStatusDocument = gql`
  mutation updateMaritalStatus($input: MaritalStatusInput!) {
    updateMaritalStatus(input: $input)
  }
`
export type UpdateMaritalStatusMutationFn = Apollo.MutationFunction<
  UpdateMaritalStatusMutation,
  UpdateMaritalStatusMutationVariables
>

/**
 * __useUpdateMaritalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMaritalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaritalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaritalStatusMutation, { data, loading, error }] = useUpdateMaritalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMaritalStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMaritalStatusMutation,
    UpdateMaritalStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMaritalStatusMutation, UpdateMaritalStatusMutationVariables>(
    UpdateMaritalStatusDocument,
    options
  )
}
export type UpdateMaritalStatusMutationHookResult = ReturnType<
  typeof useUpdateMaritalStatusMutation
>
export type UpdateMaritalStatusMutationResult = Apollo.MutationResult<UpdateMaritalStatusMutation>
export type UpdateMaritalStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateMaritalStatusMutation,
  UpdateMaritalStatusMutationVariables
>
export const UpdateMonthlyIncomeDocument = gql`
  mutation updateMonthlyIncome($input: CalculatorInput!) {
    updateMonthlyIncome(input: $input) {
      vafAmount {
        errorMessage
        amount
        status
        data
      }
      preApprovalAttempts
    }
  }
`
export type UpdateMonthlyIncomeMutationFn = Apollo.MutationFunction<
  UpdateMonthlyIncomeMutation,
  UpdateMonthlyIncomeMutationVariables
>

/**
 * __useUpdateMonthlyIncomeMutation__
 *
 * To run a mutation, you first call `useUpdateMonthlyIncomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonthlyIncomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonthlyIncomeMutation, { data, loading, error }] = useUpdateMonthlyIncomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMonthlyIncomeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMonthlyIncomeMutation,
    UpdateMonthlyIncomeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMonthlyIncomeMutation, UpdateMonthlyIncomeMutationVariables>(
    UpdateMonthlyIncomeDocument,
    options
  )
}
export type UpdateMonthlyIncomeMutationHookResult = ReturnType<
  typeof useUpdateMonthlyIncomeMutation
>
export type UpdateMonthlyIncomeMutationResult = Apollo.MutationResult<UpdateMonthlyIncomeMutation>
export type UpdateMonthlyIncomeMutationOptions = Apollo.BaseMutationOptions<
  UpdateMonthlyIncomeMutation,
  UpdateMonthlyIncomeMutationVariables
>
export const VerifyOtpDocument = gql`
  mutation verifyOtp($input: VerifyOtpInput!) {
    verifyOtp(input: $input) {
      jwt
      error
      user {
        id
        name
        email
        surname
        gender
        dob
        phoneNumber
        passportNumber
        idNumber
        marketingConsent
      }
      address {
        addressLine1
        addressLine2
        suburb
        postalCode
        residentialStatus
        timeOfLivingYears
        timeOfLivingMonths
        userId
      }
      maritalStatus {
        userId
        status
        contractType
      }
      employmentDetails {
        userId
        companyName
        industryCategory
        industry
        occupation
        phone
        employmentTimeInYears
        employmentTimeInMonths
      }
      calculator {
        userId
        monthlyGross
        monthlyNet
        additionalIncome
        monthlyExpenses
        banksWith
        email
        employed
        affordabilityAmount
      }
    }
  }
`
export type VerifyOtpMutationFn = Apollo.MutationFunction<
  VerifyOtpMutation,
  VerifyOtpMutationVariables
>

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(
    VerifyOtpDocument,
    options
  )
}
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<
  VerifyOtpMutation,
  VerifyOtpMutationVariables
>
export const AllSuburbsDocument = gql`
  query allSuburbs($filter: SuburbFilter) {
    allSuburbs(filter: $filter) {
      label
      value
    }
  }
`

/**
 * __useAllSuburbsQuery__
 *
 * To run a query within a React component, call `useAllSuburbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSuburbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSuburbsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllSuburbsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllSuburbsQuery, AllSuburbsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllSuburbsQuery, AllSuburbsQueryVariables>(AllSuburbsDocument, options)
}
export function useAllSuburbsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllSuburbsQuery, AllSuburbsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllSuburbsQuery, AllSuburbsQueryVariables>(AllSuburbsDocument, options)
}
export function useAllSuburbsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AllSuburbsQuery, AllSuburbsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AllSuburbsQuery, AllSuburbsQueryVariables>(
    AllSuburbsDocument,
    options
  )
}
export type AllSuburbsQueryHookResult = ReturnType<typeof useAllSuburbsQuery>
export type AllSuburbsLazyQueryHookResult = ReturnType<typeof useAllSuburbsLazyQuery>
export type AllSuburbsSuspenseQueryHookResult = ReturnType<typeof useAllSuburbsSuspenseQuery>
export type AllSuburbsQueryResult = Apollo.QueryResult<AllSuburbsQuery, AllSuburbsQueryVariables>
export const AllVehiclesDocument = gql`
  query allVehicles($filter: VehicleModelFilter) {
    allVehicles(filter: $filter) {
      id
      make
      mmCode
      makeCode
      masterModel
      model
      modelCode
      publicationSection
      regYear
      variant
      variantCode
      vehicleType
    }
  }
`

/**
 * __useAllVehiclesQuery__
 *
 * To run a query within a React component, call `useAllVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVehiclesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllVehiclesQuery, AllVehiclesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllVehiclesQuery, AllVehiclesQueryVariables>(AllVehiclesDocument, options)
}
export function useAllVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllVehiclesQuery, AllVehiclesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllVehiclesQuery, AllVehiclesQueryVariables>(
    AllVehiclesDocument,
    options
  )
}
export function useAllVehiclesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AllVehiclesQuery, AllVehiclesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AllVehiclesQuery, AllVehiclesQueryVariables>(
    AllVehiclesDocument,
    options
  )
}
export type AllVehiclesQueryHookResult = ReturnType<typeof useAllVehiclesQuery>
export type AllVehiclesLazyQueryHookResult = ReturnType<typeof useAllVehiclesLazyQuery>
export type AllVehiclesSuspenseQueryHookResult = ReturnType<typeof useAllVehiclesSuspenseQuery>
export type AllVehiclesQueryResult = Apollo.QueryResult<AllVehiclesQuery, AllVehiclesQueryVariables>
export const AllVehicleMakesDocument = gql`
  query allVehicleMakes($filter: VehicleMakeFilter) {
    allVehicleMakes(filter: $filter) {
      label
      value
    }
  }
`

/**
 * __useAllVehicleMakesQuery__
 *
 * To run a query within a React component, call `useAllVehicleMakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVehicleMakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVehicleMakesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllVehicleMakesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllVehicleMakesQuery, AllVehicleMakesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllVehicleMakesQuery, AllVehicleMakesQueryVariables>(
    AllVehicleMakesDocument,
    options
  )
}
export function useAllVehicleMakesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllVehicleMakesQuery, AllVehicleMakesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllVehicleMakesQuery, AllVehicleMakesQueryVariables>(
    AllVehicleMakesDocument,
    options
  )
}
export function useAllVehicleMakesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AllVehicleMakesQuery, AllVehicleMakesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AllVehicleMakesQuery, AllVehicleMakesQueryVariables>(
    AllVehicleMakesDocument,
    options
  )
}
export type AllVehicleMakesQueryHookResult = ReturnType<typeof useAllVehicleMakesQuery>
export type AllVehicleMakesLazyQueryHookResult = ReturnType<typeof useAllVehicleMakesLazyQuery>
export type AllVehicleMakesSuspenseQueryHookResult = ReturnType<
  typeof useAllVehicleMakesSuspenseQuery
>
export type AllVehicleMakesQueryResult = Apollo.QueryResult<
  AllVehicleMakesQuery,
  AllVehicleMakesQueryVariables
>
export const AllVehicleModelsDocument = gql`
  query allVehicleModels($filter: VehicleModelFilter) {
    allVehicleModels(filter: $filter) {
      label
      value
    }
  }
`

/**
 * __useAllVehicleModelsQuery__
 *
 * To run a query within a React component, call `useAllVehicleModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVehicleModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVehicleModelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllVehicleModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllVehicleModelsQuery, AllVehicleModelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllVehicleModelsQuery, AllVehicleModelsQueryVariables>(
    AllVehicleModelsDocument,
    options
  )
}
export function useAllVehicleModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllVehicleModelsQuery, AllVehicleModelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllVehicleModelsQuery, AllVehicleModelsQueryVariables>(
    AllVehicleModelsDocument,
    options
  )
}
export function useAllVehicleModelsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllVehicleModelsQuery,
    AllVehicleModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AllVehicleModelsQuery, AllVehicleModelsQueryVariables>(
    AllVehicleModelsDocument,
    options
  )
}
export type AllVehicleModelsQueryHookResult = ReturnType<typeof useAllVehicleModelsQuery>
export type AllVehicleModelsLazyQueryHookResult = ReturnType<typeof useAllVehicleModelsLazyQuery>
export type AllVehicleModelsSuspenseQueryHookResult = ReturnType<
  typeof useAllVehicleModelsSuspenseQuery
>
export type AllVehicleModelsQueryResult = Apollo.QueryResult<
  AllVehicleModelsQuery,
  AllVehicleModelsQueryVariables
>
export const OneVehicleDocument = gql`
  query oneVehicle($filter: OneVehicleFilter!) {
    oneVehicle(filter: $filter) {
      id
      make
      mmCode
      makeCode
      masterModel
      model
      modelCode
      publicationSection
      regYear
      variant
      variantCode
      vehicleType
    }
  }
`

/**
 * __useOneVehicleQuery__
 *
 * To run a query within a React component, call `useOneVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneVehicleQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOneVehicleQuery(
  baseOptions: Apollo.QueryHookOptions<OneVehicleQuery, OneVehicleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OneVehicleQuery, OneVehicleQueryVariables>(OneVehicleDocument, options)
}
export function useOneVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OneVehicleQuery, OneVehicleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OneVehicleQuery, OneVehicleQueryVariables>(OneVehicleDocument, options)
}
export function useOneVehicleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OneVehicleQuery, OneVehicleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OneVehicleQuery, OneVehicleQueryVariables>(
    OneVehicleDocument,
    options
  )
}
export type OneVehicleQueryHookResult = ReturnType<typeof useOneVehicleQuery>
export type OneVehicleLazyQueryHookResult = ReturnType<typeof useOneVehicleLazyQuery>
export type OneVehicleSuspenseQueryHookResult = ReturnType<typeof useOneVehicleSuspenseQuery>
export type OneVehicleQueryResult = Apollo.QueryResult<OneVehicleQuery, OneVehicleQueryVariables>
export const MeDocument = gql`
  query me {
    me {
      id
      name
      email
      idNumber
      passportNumber
      surname
      gender
      dob
      phoneNumber
    }
  }
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const GetAllProductCategoriesDocument = gql`
  query getAllProductCategories($filter: AllProductCategoryFilter) {
    getAllProductCategories(filter: $filter) {
      id
      name
      description
      disclaimers {
        description
      }
      products {
        id
        shortName
        longName
        pricingLogic
        pricingValue
        pricingWord
        benefits {
          id
          name
        }
      }
    }
  }
`

/**
 * __useGetAllProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllProductCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllProductCategoriesQuery,
    GetAllProductCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>(
    GetAllProductCategoriesDocument,
    options
  )
}
export function useGetAllProductCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllProductCategoriesQuery,
    GetAllProductCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>(
    GetAllProductCategoriesDocument,
    options
  )
}
export function useGetAllProductCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllProductCategoriesQuery,
    GetAllProductCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetAllProductCategoriesQuery,
    GetAllProductCategoriesQueryVariables
  >(GetAllProductCategoriesDocument, options)
}
export type GetAllProductCategoriesQueryHookResult = ReturnType<
  typeof useGetAllProductCategoriesQuery
>
export type GetAllProductCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetAllProductCategoriesLazyQuery
>
export type GetAllProductCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllProductCategoriesSuspenseQuery
>
export type GetAllProductCategoriesQueryResult = Apollo.QueryResult<
  GetAllProductCategoriesQuery,
  GetAllProductCategoriesQueryVariables
>
export const AllEmployerTypesDocument = gql`
  query allEmployerTypes($filter: EmployerTypeFilter) {
    allEmployerTypes(filter: $filter) {
      label
      value
    }
  }
`

/**
 * __useAllEmployerTypesQuery__
 *
 * To run a query within a React component, call `useAllEmployerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEmployerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEmployerTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllEmployerTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllEmployerTypesQuery, AllEmployerTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllEmployerTypesQuery, AllEmployerTypesQueryVariables>(
    AllEmployerTypesDocument,
    options
  )
}
export function useAllEmployerTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllEmployerTypesQuery, AllEmployerTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllEmployerTypesQuery, AllEmployerTypesQueryVariables>(
    AllEmployerTypesDocument,
    options
  )
}
export function useAllEmployerTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllEmployerTypesQuery,
    AllEmployerTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AllEmployerTypesQuery, AllEmployerTypesQueryVariables>(
    AllEmployerTypesDocument,
    options
  )
}
export type AllEmployerTypesQueryHookResult = ReturnType<typeof useAllEmployerTypesQuery>
export type AllEmployerTypesLazyQueryHookResult = ReturnType<typeof useAllEmployerTypesLazyQuery>
export type AllEmployerTypesSuspenseQueryHookResult = ReturnType<
  typeof useAllEmployerTypesSuspenseQuery
>
export type AllEmployerTypesQueryResult = Apollo.QueryResult<
  AllEmployerTypesQuery,
  AllEmployerTypesQueryVariables
>
export const AllOccupationTypesDocument = gql`
  query allOccupationTypes($filter: OccupationTypeFilter) {
    allOccupationTypes(filter: $filter) {
      label
      value
    }
  }
`

/**
 * __useAllOccupationTypesQuery__
 *
 * To run a query within a React component, call `useAllOccupationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOccupationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOccupationTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllOccupationTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllOccupationTypesQuery, AllOccupationTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllOccupationTypesQuery, AllOccupationTypesQueryVariables>(
    AllOccupationTypesDocument,
    options
  )
}
export function useAllOccupationTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllOccupationTypesQuery,
    AllOccupationTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllOccupationTypesQuery, AllOccupationTypesQueryVariables>(
    AllOccupationTypesDocument,
    options
  )
}
export function useAllOccupationTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllOccupationTypesQuery,
    AllOccupationTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AllOccupationTypesQuery, AllOccupationTypesQueryVariables>(
    AllOccupationTypesDocument,
    options
  )
}
export type AllOccupationTypesQueryHookResult = ReturnType<typeof useAllOccupationTypesQuery>
export type AllOccupationTypesLazyQueryHookResult = ReturnType<
  typeof useAllOccupationTypesLazyQuery
>
export type AllOccupationTypesSuspenseQueryHookResult = ReturnType<
  typeof useAllOccupationTypesSuspenseQuery
>
export type AllOccupationTypesQueryResult = Apollo.QueryResult<
  AllOccupationTypesQuery,
  AllOccupationTypesQueryVariables
>
export const OneIndustryRoleDocument = gql`
  query oneIndustryRole($filter: IndustryRoleFilter) {
    oneIndustryRole(filter: $filter) {
      id
      employerType
      employerTypeCode
      industryType
      industryTypeCode
      occupationType
      occupationTypeCode
    }
  }
`

/**
 * __useOneIndustryRoleQuery__
 *
 * To run a query within a React component, call `useOneIndustryRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneIndustryRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneIndustryRoleQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOneIndustryRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<OneIndustryRoleQuery, OneIndustryRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OneIndustryRoleQuery, OneIndustryRoleQueryVariables>(
    OneIndustryRoleDocument,
    options
  )
}
export function useOneIndustryRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OneIndustryRoleQuery, OneIndustryRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OneIndustryRoleQuery, OneIndustryRoleQueryVariables>(
    OneIndustryRoleDocument,
    options
  )
}
export function useOneIndustryRoleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OneIndustryRoleQuery, OneIndustryRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OneIndustryRoleQuery, OneIndustryRoleQueryVariables>(
    OneIndustryRoleDocument,
    options
  )
}
export type OneIndustryRoleQueryHookResult = ReturnType<typeof useOneIndustryRoleQuery>
export type OneIndustryRoleLazyQueryHookResult = ReturnType<typeof useOneIndustryRoleLazyQuery>
export type OneIndustryRoleSuspenseQueryHookResult = ReturnType<
  typeof useOneIndustryRoleSuspenseQuery
>
export type OneIndustryRoleQueryResult = Apollo.QueryResult<
  OneIndustryRoleQuery,
  OneIndustryRoleQueryVariables
>
