import { SearchIcon } from '@chakra-ui/icons'
import { InputGroup, Stack, Input, InputLeftElement } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAuth } from '../../context/AuthProvider'

type SearchBoxProps = {
  search: (text: string | undefined) => void
}

const SearchBox = ({ search }: SearchBoxProps): React.ReactElement<SearchBoxProps> => {
  const { appName } = useAuth()
  const [text, setText] = useState<string | undefined>()

  const inputSearch = (key: string | undefined): void => {
    if (key === 'Enter') search(text)
  }

  return (
    <Stack alignSelf="flex-start">
      <InputGroup size="md" borderRadius="8px">
        <InputLeftElement>
          <SearchIcon color="#667085" />
        </InputLeftElement>
        <Input
          data-testid="dashboard-search-vehicle"
          bg="base.100"
          placeholder="Search saved vehicles "
          onChange={(event) => setText(event.target.value)}
          onKeyDown={(event) => inputSearch(event.key)}
          borderRadius={appName === 'vwfs' ? '8px' : '0'}
        />
      </InputGroup>
    </Stack>
  )
}

export default SearchBox
