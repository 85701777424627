import z from 'zod'
import { AddressInput } from '../../generated'

export const addressDefaultValues: AddressInput = {
  addressLine1: '',
  addressLine2: '',
  suburb: undefined,
  residentialStatus: '',
  timeOfLivingYears: 0,
  timeOfLivingMonths: 0
}

export const addressSchema = z.object({
  addressLine1: z.string().min(1, 'Please add at least one address').trim(),
  addressLine2: z.string().trim(),
  suburb: z
    .object({
      label: z.string({ required_error: 'This is required' }),
      value: z.string({ required_error: 'This is required' })
    })
    .required(),
  residentialStatus: z
    .object({
      label: z.string({ required_error: 'This is required' }),
      value: z.string()
    })
    .required(),
  timeOfLivingYears: z.number().min(0, 'This is required'),
  timeOfLivingMonths: z.number().min(0, 'This is required')
})
