import { Center, Spinner } from '@chakra-ui/react'
import * as React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import {
  Address,
  AuthPayload,
  AuthenticationResponse,
  Calculator,
  EmploymentDetails,
  LoginInput,
  MaritalStatus,
  RegisterInput,
  User,
  VerifyOtpInput,
  useDeleteOtpMutation,
  useLoginMutation,
  useRegisterMutation,
  useVerifyOtpMutation
} from '../../generated'
import { useBrowserStorage } from '../../hooks'
import { IN_PROGRESS_FINANCE_APPLICATION_KEY, TOKEN_STORAGE_KEY } from '../../utils'
import { userAtom } from '../atoms'

type TokenStorage = string | null | undefined

/**
 * `AuthProviderProps` is an object with the following properties: `passportNumber`, `idNumber`,
 * `setIdNumber`, `setPassportNumber`, `children`, `useAuth`, `logout`, `isAuthenticating`,
 * `isAuthenticated`, `user`, `verifyOtp`, `register`, `login`, `setUser`, `persistUser`, `address`,
 * `maritalStatus`, `employmentDetails`.
 * @property {string} passportNumber - The passport number of the user
 * @property {string} idNumber - The user's ID number
 * @property setIdNumber - This is a React state setter function that sets the idNumber state.
 * @property setPassportNumber - This is a React state setter function that sets the passport number.
 * @property children - This is the component that will be wrapped by the AuthProvider.
 * @property useAuth - This is a hook that returns the properties of the AuthProvider.
 * @property logout - This is a function that logs the user out.
 * @property {boolean} isAuthenticating - This is a boolean that indicates whether the user is
 * currently authenticating.
 * @property {boolean} isAuthenticated - This is a boolean that tells us if the user is authenticated
 * or not.
 * @property {User} user - This is the user object that is returned from the server.
 * @property verifyOtp - This is a function that takes in an object of type VerifyOtpInput and returns
 * a promise.
 * @property register - This is a function that takes in a RegisterInput object and returns a Promise.
 * @property login - This is the function that will be called when the user clicks the login button.
 * @property setUser - This is a React state setter that sets the user state.
 * @property persistUser - This is a function that persists the user's data to localStorage.
 * @property {Address} address - Address
 * @property {MaritalStatus} maritalStatus - MaritalStatus data Object
 * @property {EmploymentDetails} employmentDetails - EmploymentDetails data Object
 */
type AuthProviderProps = {
  passportNumber: string
  idNumber: string
  setIdNumber: React.Dispatch<React.SetStateAction<string | undefined>>
  setPassportNumber: React.Dispatch<React.SetStateAction<string | undefined>>
  children: React.ReactNode
  useAuth: () => Partial<AuthProviderProps>
  logout: () => void
  isAuthenticating: boolean
  isAuthenticated: boolean
  user: User
  verifyOtp: (input: VerifyOtpInput) => Promise<AuthPayload>
  register: (input: RegisterInput) => Promise<AuthenticationResponse>
  login: (input: LoginInput) => Promise<AuthenticationResponse>
  deleteOTP: (input: LoginInput) => Promise<void>
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>
  persistUser: (data: AuthPayload) => void
  address: Address
  maritalStatus: MaritalStatus
  employmentDetails: EmploymentDetails
  calculatorData?: Calculator
  baseURL: string
  appName: string
}

const AuthContext = React.createContext<Partial<AuthProviderProps>>({})

export const useAuth = () => React.useContext(AuthContext)

export function RequireAuth({
  children,
  baseURL
}: {
  children: React.ReactElement
  baseURL: string
}) {
  const auth = useAuth()
  const location = useLocation()
  const sessionUser = window.sessionStorage.getItem('vw_User')
  if (!sessionUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={baseURL} state={{ from: location }} replace />
  }
  if (!auth.user) {
    auth.persistUser?.(JSON.parse(sessionUser))
  }

  return children
}

function AuthProvider({
  children,
  baseURL,
  appName
}: {
  children: React.ReactElement
  baseURL: string
  appName: string
}): React.ReactElement {
  const [localToken, setLocalToken, removeLocalToken] = useBrowserStorage<TokenStorage>(
    TOKEN_STORAGE_KEY,
    'local'
  )
  const navigate = useNavigate()

  const [loginMutation] = useLoginMutation()
  const [registerMutation] = useRegisterMutation()
  const [verifyOtpMutation] = useVerifyOtpMutation()
  const [deleteOTPMutation] = useDeleteOtpMutation()

  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [isAuthenticating, setIsAuthenticating] = React.useState(true)
  const [idNumber, setIdNumber] = React.useState<string | undefined>()
  const [passportNumber, setPassportNumber] = React.useState<string | undefined>()

  const [user, setUser] = useRecoilState(userAtom)
  const [address, setAddress] = React.useState<Address>()
  const [maritalStatus, setMaritalStatus] = React.useState<MaritalStatus>()
  const [employmentDetails, setEmploymentDetails] = React.useState<EmploymentDetails>()
  const [calculatorData, setCalculatorData] = React.useState<Calculator>()

  React.useEffect(() => {
    if (localToken) {
      setIsAuthenticated(true)
    }
    setIsAuthenticating(false)
  }, [localToken])

  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.
  if (isAuthenticating) {
    return (
      <Center width="100%" height="100vh">
        <Spinner />
      </Center>
    )
  }

  const persistUser = (data: AuthPayload) => {
    localStorage.clear()
    const u = data?.user as User
    setLocalToken(data.jwt)
    setUser(u)
    setAddress(data.address as Address | undefined)
    setMaritalStatus(data.maritalStatus as MaritalStatus | undefined)
    setEmploymentDetails(data.employmentDetails as EmploymentDetails | undefined)
    setCalculatorData(data.calculator as Calculator | undefined)
    window.sessionStorage.setItem('vw_User', JSON.stringify({ ...data }))
    setIsAuthenticated(true)
  }

  const logout = () => {
    removeLocalToken()
    setIsAuthenticated(false)
    sessionStorage.removeItem('vw_User')
    window.sessionStorage.removeItem('vw_User')
    sessionStorage.removeItem(IN_PROGRESS_FINANCE_APPLICATION_KEY)
    localStorage.clear()
    setUser(undefined)
    navigate(`${baseURL}`)
  }

  const login = async (input: LoginInput) => {
    const res = await loginMutation({
      variables: { input }
    })

    if (!res.data?.login?.error) {
      setIdNumber(input.idNumber as string)
      setPassportNumber(input.passportNumber as string)
      navigate(`${baseURL}otp`)
    }

    return res.data?.login as AuthenticationResponse
  }

  const deleteOTP = async (input: LoginInput) => {
    await deleteOTPMutation({
      variables: { input }
    })
    setIdNumber(input.idNumber as string)
    setPassportNumber(input.passportNumber as string)
  }

  const register = async (input: RegisterInput) => {
    const idNumberOrPassp = input.idNumber ?? input.passportNumber

    if (idNumberOrPassp?.match(/^[0-9]+$/)) {
      input.idNumber = idNumberOrPassp
      input.dob = undefined
    } else {
      input.idNumber = undefined
      input.passportNumber = idNumberOrPassp
    }
    const res = await registerMutation({
      variables: {
        input
      }
    })

    if (!res.data?.register?.error) {
      setIdNumber(input.idNumber as string)
      setPassportNumber(input.passportNumber as string)
      navigate(`${baseURL}otp`)
    }

    return res.data?.register as AuthenticationResponse
  }

  const verifyOtp = async (input: VerifyOtpInput) => {
    const { data } = await verifyOtpMutation({
      variables: {
        input
      }
    })

    if (!data?.verifyOtp?.error) {
      persistUser(data?.verifyOtp as AuthPayload)
      setIdNumber(undefined)
      setPassportNumber(undefined)
      navigate(`${baseURL}auth/dashboard`)
    }

    return data?.verifyOtp as AuthPayload
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        logout,
        passportNumber,
        idNumber,
        setIdNumber,
        setPassportNumber,
        isAuthenticated,
        isAuthenticating,
        user,
        setUser,
        verifyOtp,
        persistUser,
        address,
        maritalStatus,
        employmentDetails,
        calculatorData,
        baseURL,
        appName,
        deleteOTP
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
