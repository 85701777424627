import z from 'zod'

export const employeeDetailsDefaultValues = {
  monthlyGross: 0,
  monthlyNet: 0,
  additionalIncome: 0,
  monthlyExpenses: 0,
  email: '',
  banksWith: 'Standard Bank'
}

export const exployeeDetailsSchema = z.object({
  monthlyGross: z.preprocess(
    (val) => {
      if (typeof val === 'string') return parseInt(val, 10)
      return val
    },
    z
      .number({
        required_error: 'Monthly gross is required',
        invalid_type_error: 'Monthly gross is required'
      })
      .gt(0, 'Monthly gross should be more than 0.')
      .max(1000000000, 'Please add a maximum value of 1 billion')
  ),
  monthlyNet: z.preprocess(
    (val) => {
      if (typeof val === 'string') return parseInt(val, 10)
      return val
    },
    z
      .number({
        required_error: 'Monthly net is required',
        invalid_type_error: 'Monthly net is required'
      })
      .gt(0, 'Monthly net should be more than 0.')
      .max(1000000000, 'Please add a maximum value of 1 billion')
  ),
  monthlyExpenses: z.preprocess(
    (val) => {
      if (typeof val === 'string') return parseInt(val, 10)
      return val
    },
    z
      .number({
        required_error: 'Monthly expenses is required',
        invalid_type_error: 'Monthly expenses is required'
      })
      .gt(0, 'Monthly expenses should be more than 0.')
      .max(1000000000, 'Please add a maximum value of 1 billion')
  ),
  banksWith: z
    .object({
      label: z.string({
        required_error: 'Bank name is required',
        invalid_type_error: 'Bank name is required'
      }),
      value: z.string({
        required_error: 'Bank name is required',
        invalid_type_error: 'Bank name is required'
      })
    })
    .required(),
  email: z
    .string({
      required_error: 'Email is required',
      invalid_type_error: 'Email is required'
    })
    .email('Please enter a valid email')
})

export const banksDefaultValues = {
  name: '',
  points: 0
}

export const banksValuesSchema = z.object({
  name: z.string(),
  points: z
    .string()
    .regex(/^[0-9]+$/, 'Please enter a number higher than 0')
    .max(10)
    .min(1, 'Please add a minimum value of 0')
})
