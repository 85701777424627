import { CheckCircleIcon } from '@chakra-ui/icons'
import { Avatar, Button, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useAuth } from '../../../context/AuthProvider'
import { useData } from '../../../context/UserDataProvider'
import AffordabilityAmountText from '../../AffordabilityAmountText'

type ApprovedPreApprovalModalProps = {
  onGoToDashboard?: () => void
  onCompleteDetails?: () => void
  onOpenSettlementDrawer: () => void
}
export default function ApprovedPreApprovalModal({
  onGoToDashboard,
  onCompleteDetails,
  onOpenSettlementDrawer
}: ApprovedPreApprovalModalProps): React.ReactElement {
  const { affordableAmount } = useData()
  const { user } = useAuth()

  return (
    <Flex
      data-testid="approve-modal"
      alignItems="center"
      p="24px"
      flexDirection="column"
      alignSelf="center"
      gap="32px"
    >
      <Avatar icon={<CheckCircleIcon />} p="2px" backgroundColor="primary.50" />
      <Text data-testid="modal-title" variant="title" fontSize="16px" textAlign="center">
        Great news {user?.name}! Based on the information you’ve provided, you can afford to finance
        a maximum amount of
      </Text>
      <Flex flexDirection="column" alignItems="center" gap="16px">
        <AffordabilityAmountText amount={affordableAmount} />
        <Text variant="faint" textAlign="center">
          Get a personalised deal structure for this vehicle right away. We just need a few more
          details.
        </Text>
      </Flex>
      <Flex gap="12px" direction="column">
        <Button
          data-testid="check-debt-settlement-button"
          onClick={onOpenSettlementDrawer}
          variant="outline"
        >
          Check debt settlement
        </Button>
        <Button data-testid="dashboard-button" onClick={onGoToDashboard} variant="outline">
          Go to Dashboard
        </Button>
        <Button data-testid="complete-details-button" onClick={onCompleteDetails}>
          Complete Details
        </Button>
      </Flex>
    </Flex>
  )
}
