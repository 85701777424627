import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text
} from '@chakra-ui/react'
import * as React from 'react'

type Props = {
  title: string
  description: string
}

const Accordion: React.FC<Props> = ({ title, description }) => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <Text
            data-testid="landing-page-description"
            fontSize={['md', '15px']}
            variant={'subHeading'}
            fontWeight={500}
          >
            {title}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Text data-testid="landing-page-description" fontSize={14} fontWeight={300}>
          {description}
        </Text>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default Accordion
