import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { Flex, Tooltip, Text } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { useAuth } from '../../context/AuthProvider'
import { useData } from '../../context/UserDataProvider'
import formatFinanceAmount from '../../utils/math/format/formatFinanceAmount'

type AffordabilityAmountTextProps = {
  amount?: string
}
export default function AffordabilityAmountText({
  amount
}: AffordabilityAmountTextProps): ReactElement {
  const { affordableAmount } = useData()
  const { calculatorData } = useAuth()
  const financeAmountInfo = amount
    ? 'Amount you will be financed, for the selected vehicle.'
    : 'This is the amount you can afford to finance.'
  /// Get the amount to format, based on the amount prop or the affordableAmount from the context
  const amountToFormat = () => {
    if (amount) return amount
    return affordableAmount ?? calculatorData?.affordabilityAmount.toString()
  }
  return (
    <Flex gap="16px" alignItems="center">
      <Text fontWeight={800} fontSize="30px" color={'primary.500'}>
        R {formatFinanceAmount(amountToFormat())}
      </Text>
      <Tooltip label={financeAmountInfo}>
        <QuestionOutlineIcon />
      </Tooltip>
    </Flex>
  )
}
