export const TOKEN_STORAGE_KEY = 'vwfs-token'
export const footerOptionsvalues = {
  email: 'finance@vwfs.co.za',
  phone: '0861 500 400',
  fax: '0861 101 833',
  openHours: ['Weekdays 8am to 5pm', 'Saturday 8am to 12pm'],
  additionalText:
    'Volkswagen Financial Services South Africa (Pty) Ltd. Reg No 2013/133698/07 VAT No.4760263576. An Authorised Financial Services (FSP No.44827) and Registered Credit Provider (NCRCP6635).'
}

export const audiFooterOptionsvalues = {
  ...footerOptionsvalues,
  email: 'finance@audifs.co.za',
  phone: '0861 112 828',
  additionalText:
    'Audi Financial Services, a division of Volkswagen Financial Services South Africa (Pty) Ltd An authorised Financial Services and Credit Provider. (NCRCP6635)'
}
export const IN_PROGRESS_FINANCE_APPLICATION_KEY = 'financeApplication'

export const SERVER_ERROR_MESSAGE =
  'We are currently experiencing a high volume of traffic. Kindly submit the form again. Thank you for your patience.'

export const ERROR_MESSAGES: { [key: string]: string } = {
  'Something went wrong, Please try again.': SERVER_ERROR_MESSAGE
}
