import {
  Textarea as ChakraTextArea,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  TextareaProps
} from '@chakra-ui/react'
import * as React from 'react'
import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form'
import { useAuth } from '../../../context/AuthProvider'

type TextAreaProps = TextareaProps & {
  label?: string
  name: FieldPath<FieldValues>
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  onFormHelperClick?: () => void
  customError?: string
}

function TextArea({
  name,
  leftIcon,
  label,
  rightIcon,
  customError,
  ...rest
}: TextAreaProps): React.ReactElement {
  const { control } = useFormContext()
  const { appName } = useAuth()

  const { fieldState, field } = useController({
    name,
    control
  })

  const showError = !!fieldState.error?.message || !!customError

  return (
    <FormControl {...fieldState} isInvalid={showError}>
      <FormLabel id={name}>{label}</FormLabel>
      <InputGroup>
        {leftIcon && (
          <InputLeftElement
            width={'36px'}
            borderRadius={'8px 0px 0px 8px'}
            borderRight={'1px solid brand.1300'}
          >
            {leftIcon}
          </InputLeftElement>
        )}
        <ChakraTextArea
          {...field}
          {...rest}
          fontWeight={300}
          borderRadius={appName === 'vwfs' ? '8px' : '0'}
          aria-labelledby={name}
        />
        {rightIcon && <InputRightElement>{rightIcon}</InputRightElement>}
      </InputGroup>
      {showError && (
        <FormErrorMessage data-testid={`text-input-${name}`}>
          {fieldState.error?.message ?? customError}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default TextArea

TextArea.defaultProps = {}
