import z from 'zod'

export const monthlyExpenseDefaultValues = {
  rent: '',
  householdExpenses: '',
  transportCost: '',
  entertainmentExpense: '',
  educationExpense: '',
  childMaintenance: '',
  otherCosts: ''
}

export const monthlyExpenseSchema = z.object({
  rent: z
    .string()
    .regex(/^[0-9]+$/, 'Please enter a number higher than 0')
    .max(10)
    .min(1, 'Please add a minimum value of 0'),
  householdExpenses: z
    .string()
    .regex(/^[0-9]+$/, 'Please enter a number higher than 0')
    .max(10)
    .min(1, 'Please add a minimum value of 0'),
  transportCost: z.string().regex(/^[0-9]+$/, 'Please enter a number higher than 0'),
  entertainmentExpense: z
    .string()
    .regex(/^[0-9]+$/, 'Please enter a number higher than 0')
    .max(10)
    .min(1, 'Please add a minimum value of 0'),
  educationExpense: z
    .string()
    .regex(/^[0-9]+$/, 'Please enter a number higher than 0')
    .max(10)
    .min(1, 'Please add a minimum value of 0'),
  childMaintenance: z
    .string()
    .regex(/^[0-9]+$/, 'Please enter a number higher than 0')
    .max(10)
    .min(1, 'Please add a minimum value of 0'),
  otherCosts: z
    .string()
    .regex(/^[0-9]+$/, 'Please enter a number higher than 0')
    .max(10)
    .min(1, 'Please add a minimum value of 0')
})
