import React from 'react'
import { LoginForm } from '../../components'
import HomePageLayout from '../HomePageLayout'

type Props = {
  landingImage: string
  logo: string
}

function LoginScreen({ landingImage, logo }: Props): React.ReactElement {
  return (
    <HomePageLayout
      landingImage={landingImage}
      logo={logo}
      title="Log In"
      subTitle="Login to your account"
      contentPosition="center"
    >
      <LoginForm />
    </HomePageLayout>
  )
}

export default LoginScreen
