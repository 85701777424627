import React, { ReactElement } from 'react'
import { Box, Circle, HStack, VStack } from '@chakra-ui/react'

type HorizontalStepperProps = {
  steps: string[]
  currentStep: number
}

export default function HorizontalStepper({
  steps,
  currentStep
}: HorizontalStepperProps): ReactElement {
  return (
    <HStack spacing={[2, 4]}>
      {steps.map((step, index) => (
        <HStack key={`hori-${step}`}>
          <Circle
            data-testid={`stepper-${step}`}
            size={['30px', '40px']}
            bg={index < currentStep || currentStep === index ? 'primaryDark.500' : 'base.300'}
            color="white"
            fontWeight="bold"
          >
            {index + 1}
          </Circle>
          {index < steps.length - 1 && (
            <Box>
              <VStack spacing={0} alignItems="start">
                <Box
                  width="20px"
                  height="1px"
                  bg={index < currentStep ? 'primaryDark.500' : 'base.300'}
                />
              </VStack>
              <Box
                height="1px"
                bg={index < currentStep ? 'primaryDark.500' : 'base.300'}
                flexGrow="1"
              />
            </Box>
          )}
        </HStack>
      ))}
    </HStack>
  )
}
