import { Button, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'

export type InfoCardProps = {
  title: string
  info: string
  callBack?: () => void
}

function InfoCard({ title, info, callBack }: InfoCardProps) {
  return (
    <Flex>
      <Stack>
        <Heading data-testid="info-card-title">{title}</Heading>
        <Text data-testid="info-card-decription">{info}</Text>

        {callBack && (
          <Button
            data-testid="info-card-action-button"
            loadingText="working"
            size="lg"
            backgroundColor="brand.100"
            color="white"
            mt={10}
            alignSelf="flex-end"
            _hover={{
              backgroundColor: 'brand.500'
            }}
            onClick={callBack}
          >
            GOT IT
          </Button>
        )}
      </Stack>
    </Flex>
  )
}

export default InfoCard
