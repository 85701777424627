export default function formatFinanceAmount(amount: string | undefined): string {
  if (!amount) return ''
  amount = Math.ceil(Number(amount)).toString()
  const num = Number(amount)
  return new Intl.NumberFormat('en-ZA').format(num) // South Africa locale
}
export function reverseFormatNumber(formattedNum: string): number {
  const withoutSpaces = formattedNum.replace(/\s/g, '')
  const withoutComma = withoutSpaces.replace(/,/, '')
  const withDotDecimal = withoutComma.replace(/,/, '.')
  return parseFloat(withDotDecimal)
}
