import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'

type VWModalProps = ModalProps & {
  modalBody: ReactElement
  modalTitle?: string
  footerElement?: ReactElement
  showFooter?: boolean
}
function VWModal({
  isOpen,
  onClose,
  modalBody,
  modalTitle,
  footerElement,
  showFooter = true,
  ...rest
}: VWModalProps): React.ReactElement {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
      closeOnOverlayClick={showFooter}
      size={['xs', 'sm', 'md', 'lg', 'xl']}
    >
      <ModalOverlay />
      <ModalContent
        bg="#FFFFFF"
        boxShadow="0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)"
        borderRadius="12px"
      >
        {modalTitle && <ModalHeader>{modalTitle}</ModalHeader>}
        {showFooter && <ModalCloseButton />}
        <ModalBody>{modalBody}</ModalBody>
        {showFooter && <ModalFooter>{footerElement}</ModalFooter>}
      </ModalContent>
    </Modal>
  )
}

export default VWModal
